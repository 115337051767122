const variables = {
    en: {
        connect_keplr: 'Connect with Keplr',
        meta_fellas: 'Metafellas',
        meta_fellas_content: 'Welcome to the Metafellas Mint! Follow the simple steps below to collect some of our 8,765 unique NFTs. These intrepid little explorers of the Cosmos are looking for treasure… and they’ll show you the way!',
        claim_fee_grant: '1) Claim Fee Grant',
        view_on_marketplace: 'View on marketplace',
        view_marketplace: 'View marketplace',
        marketplace: 'Marketplace',
        claim_fee_grant_content: 'Collectors can request for a fee grant allowance to collect, transfer, list & burn NFTs.',
        claim_fee_grant_content1: ' tokens will not be visible in your wallet but the tx fee will be paid by the app.',
        request_fee_grant: 'Request Fee Grant',
        deposit_atom1: '2) Deposit/Withdraw',
        deposit_atom2: 'to mint NFTs ',
        deposit_atom_content1: 'Use the power of IBC to deposit and withdraw',
        deposit_atom_content2: 'tokens required for minting NFTs',
        deposit: 'Deposit',
        withdraw: 'Withdraw',
        mint_nft: 'Mint NFTs',
        mint_nft_content: 'Select total quantity of NFTs and click Mint',
        whitelist_drop: 'Whitelist Drop',
        active: 'Active',
        in_active: 'In-Active',
        price_per_nft: 'Price per NFT',
        select_quantity: 'Select Quantity',
        total: 'Total',
        mint: 'Mint',
        left: 'Left',
        not_whitelisted: 'Your account isn’t whitelisted for the drop.',
        omniflix_network: 'OmniFlix Network',
        flix: 'FLIX',
        sold_out: 'Sold Out',

        deposit_ibc_asset: 'Deposit IBC Asset',
        ibc_transfer: 'IBC Transfer',
        available: 'Available',
        approval_pending: 'Approval Pending',
        withdraw_ibc_asset: 'Withdraw IBC Asset',
        ibc_withdraw: 'IBC Withdraw',
        check_later: 'Transaction is in process. check after some time.',

        mint_nft_confirm_details: 'Mint NFT Confirmation Details',
        message_type: 'Message Type',
        to: 'To',
        denom_symbol: 'Denom Symbol',
        denom_name: 'Denom Name',
        total_nfts: 'Total NFTs',
        total_price: 'Total Price',
        cancel: 'Cancel',
        confirm: 'Confirm',

        // mint nft success dialog
        mint_requested_successfully: 'Mint Requested Successfully',
        nft_s_minted: 'NFTs',
        tokens_transferred: 'Tokens Transferred',
        close: 'Close',
        go_to_market_place: 'Go to MarketPlace',

        confirm_deposit_dialog: 'Confirm IBC Deposit Details',
        selected_token: 'Selected Token',
        amount_deposit: 'Amount to deposit',
        from: 'From',

        confirm_withdraw_dialog: 'Confirm IBC Withdraw Details',
        amount_withdraw: 'Amount to Withdraw',

        connecting: 'Connecting',
        processing: 'Processing',
        allowance_received: 'Fee Grant Allowance Received',
        fee_grant_expired: 'Fee Grant allowance Expired',
        allowance: 'Allowance',
        expiry: 'Expiry',

        // mint request queue
        transaction_in_queue: 'Your Mint Request is being processed in the queue',
        queue_number: 'Queue Number',
        view_txs: 'View Txns',
        transaction_history: 'Transaction History',
        transaction_success: 'Mint Request processed successfully',
        transaction_failed: 'Your Mint Request has failed',
        transactions_in_queue: 'Mint Requests in Queue',
        in_queue: 'In Queue',
        mint_request_created: 'Mint request created',
        invalid_transfer_amount: 'Invalid Transfer amount',
        transaction_in_process: 'Transaction is in progress',
        transaction_expired: 'Transaction Expired',
        transfers_history: 'Transfers History',
        transfer_success: 'Your refund has been processed successfully',
        refund: 'Refund request created',

        // Disclaimer
        disclaimer: 'Disclaimer',
        disclaimer_info: 'OmniFlix Network is a decentralized peer-to-peer blockchain that creators and communities can use to mint, manage, distribute and exchange NFTs for fungible assets. OmniFlix Hub, the blockchain that powers the OmniFlix Network is made up of free, public, and open-source software. Your use of OmniFlix involves various risks, including, but not limited, to losses while digital assets are being listed on the OmniFlix Market and the losses due to the fluctuation of prices of tokens before/after the sale of tokens. Before using the apps on the OmniFlix Hub, you should review the relevant information and documentation to make sure you understand how OmniFlix works and how NFT minting, management, listing, delisting, transfer and burning of NFTs work. Similar to how media delivery and infrastructure protocols like FTP work where access is not through a single client but through multiple clients that are consistent and adhere to the protocol, you can interact with the OmniFlix Hub blockchain through several interfaces on web or mobile. You are responsible for doing your own due diligence on those interfaces to understand the fees/cost and risks they present.',
        disclaimer_info2: 'As described in the OmniFlix Hub licenses, the OmniFlix Hub software is provided “AS IS” where the utility is AT YOUR OWN RISK, AND WITHOUT WARRANTIES OF ANY KIND. Although Flix Labs Pte. Ltd. (“Flix Labs”) released and developed much of the initial code for the OmniFlix Hub and the protocol, it does NOT provide, own, or control the OmniFlix protocol right now, which is run by a decentralized validator set of 64 nodes that secure the network from around the world. Upgrades and modifications to the protocol are managed in a community-driven way by holders of the FLIX governance token. No developer or entity involved in creating the OmniFlix protocol (development organizations, grantees, partners, etc.) will be liable for any claims or damages whatsoever associated with your use, inability to use, or your interaction with other users of the OmniFlix protocol, including any direct, indirect, incidental, special, exemplary, punitive or consequential damages, or loss of profits, cryptocurrencies, tokens, or anything else of value.',
        disclaimer_info3: 'By clicking on the Agree and continue button, you agree that you have performed your due diligence and have no concerns regarding the nature of the app or interface (OmniFlix Market or a subdomain of that).',
        disclaimer_info4: 'Click Agree and continue only if you are sure you know what you are doing and are confident in your skills to manage keys, services, devices, tokens (NFTs and fungibles) that are used within the app.',
        agree_and_continue: 'Agree and continue',
        share_tweet: 'Share',
        tweet_text: 'Just snagged my @Tribbles with $IST on #OmniFlix 💪 \n',
        tweet_text1: 'Let the cosmic fluffy rebellion continue \n\n',
        tweet_text2: 'Collect yours on👇 \n',
    },
};

export default variables;
