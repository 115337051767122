export const CONNECT_IBC_ACCOUNT_IN_PROGRESS = 'CONNECT_IBC_ACCOUNT_IN_PROGRESS';
export const CONNECT_IBC_ACCOUNT_SUCCESS = 'CONNECT_IBC_ACCOUNT_SUCCESS';
export const CONNECT_IBC_ACCOUNT_ERROR = 'CONNECT_IBC_ACCOUNT_ERROR';

export const IBC_BALANCE_FETCH_IN_PROGRESS = 'IBC_BALANCE_FETCH_IN_PROGRESS';
export const IBC_BALANCE_FETCH_SUCCESS = 'IBC_BALANCE_FETCH_SUCCESS';
export const IBC_BALANCE_FETCH_ERROR = 'IBC_BALANCE_FETCH_ERROR';

export const AMINO_SIGN_IBC_TX_IN_PROGRESS = 'AMINO_SIGN_IBC_TX_IN_PROGRESS';
export const AMINO_SIGN_IBC_TX_SUCCESS = 'AMINO_SIGN_IBC_TX_SUCCESS';
export const AMINO_SIGN_IBC_TX_ERROR = 'AMINO_SIGN_IBC_TX_ERROR';

export const TIMEOUT_HEIGHT_FETCH_IN_PROGRESS = 'TIMEOUT_HEIGHT_FETCH_IN_PROGRESS';
export const TIMEOUT_HEIGHT_FETCH_SUCCESS = 'TIMEOUT_HEIGHT_FETCH_SUCCESS';
export const TIMEOUT_HEIGHT_FETCH_ERROR = 'TIMEOUT_HEIGHT_FETCH_ERROR';

export const IBC_TX_IN_PROGRESS_SET = 'IBC_TX_IN_PROGRESS_SET';

export const IBC_TOKENS_LIST_FETCH_IN_PROGRESS = 'IBC_TOKENS_LIST_FETCH_IN_PROGRESS';
export const IBC_TOKENS_LIST_FETCH_SUCCESS = 'IBC_TOKENS_LIST_FETCH_SUCCESS';
export const IBC_TOKENS_LIST_FETCH_ERROR = 'IBC_TOKENS_LIST_FETCH_ERROR';
