import { MESSAGE_SHOW, SNACKBAR_HIDE } from '../constants/snackbar';
import {
    AMINO_SIGN_IBC_TX_ERROR,
    AMINO_SIGN_IBC_TX_SUCCESS,
    CONNECT_IBC_ACCOUNT_ERROR,
    IBC_BALANCE_FETCH_ERROR,
    IBC_TX_IN_PROGRESS_SET,
    TIMEOUT_HEIGHT_FETCH_ERROR,
} from '../constants/IBCTokens';
import {
    CONNECT_BC_ACCOUNT_ERROR,
    FEE_GRANT_ALLOWANCE_ERROR,
    FEE_GRANT_ALLOWANCE_SUCCESS,
    VERIFY_ACCOUNT_ERROR,
    VERIFY_ACCOUNT_SUCCESS,
} from '../constants/account';
import { CONNECT_KEPLR_ACCOUNT_ERROR, TX_HASH_FETCH_SUCCESS, TX_SIGN_AND_BROAD_CAST_ERROR } from '../constants/wallet';
import { MINT_LIMIT_FETCH_ERROR, MINT_REQUEST_ADD_ERROR } from '../constants/mint';

const snackbar = (state = {
    open: false,
    message: '',
    variant: null,
    hash: null,
    explorer: null,
}, action) => {
    switch (action.type) {
    case FEE_GRANT_ALLOWANCE_SUCCESS:
    case FEE_GRANT_ALLOWANCE_ERROR:
    case CONNECT_BC_ACCOUNT_ERROR:// BC Account
    case CONNECT_KEPLR_ACCOUNT_ERROR:
    case VERIFY_ACCOUNT_ERROR:
    case VERIFY_ACCOUNT_SUCCESS:
    case TX_SIGN_AND_BROAD_CAST_ERROR:
    case TX_HASH_FETCH_SUCCESS:
    case CONNECT_IBC_ACCOUNT_ERROR:// IBC Account
    case IBC_BALANCE_FETCH_ERROR:
    case AMINO_SIGN_IBC_TX_SUCCESS:
    case AMINO_SIGN_IBC_TX_ERROR:
    case TIMEOUT_HEIGHT_FETCH_ERROR:
    case MINT_REQUEST_ADD_ERROR:// Mint
    case MINT_LIMIT_FETCH_ERROR:
    case MESSAGE_SHOW:
        return {
            open: true,
            message: action.message,
            variant: action.variant || null,
            hash: action.hash || null,
            explorer: action.explorer || null,
        };
    case SNACKBAR_HIDE:
        return {
            open: false,
            message: '',
            variant: null,
            hash: null,
            explorer: null,
        };
    case IBC_TX_IN_PROGRESS_SET:
        if (action.type === false) {
            return {
                open: false,
                message: '',
            };
        }
        return state;
    default:
        return state;
    }
};

export default snackbar;
