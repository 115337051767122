import React from 'react';
import './app.css';
import Router from './Router';
import Snackbar from './containers/Snackbar';
import Disclaimer from './containers/Home/Disclaimer';

const App = () => {
    return (
        <div className="app">
            <Router/>
            <Snackbar/>
            <Disclaimer/>
        </div>
    );
};

export default App;
