import { combineReducers } from 'redux';
import BCDetails from './BCDetails';
import wallet from './wallet';
import {
    ACCESS_TOKEN_FETCH_ERROR,
    ACCESS_TOKEN_FETCH_IN_PROGRESS,
    ACCESS_TOKEN_FETCH_SUCCESS,
    FEE_GRANT_ALLOWANCE_ERROR,
    FEE_GRANT_ALLOWANCE_IN_PROGRESS,
    FEE_GRANT_ALLOWANCE_SUCCESS,
    VERIFY_ACCOUNT_SUCCESS,
} from '../../constants/account';
import IBCTokens from './IBCTokens';
import { DISCONNECT_SET } from '../../constants/wallet';
import { config } from '../../config';

const feeGrant = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case FEE_GRANT_ALLOWANCE_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FEE_GRANT_ALLOWANCE_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case FEE_GRANT_ALLOWANCE_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_SET:
        return {
            ...state,
            value: {},
        };
    default:
        return state;
    }
};

const token = (state = {
    inProgress: false,
    value: localStorage.getItem('acToken_of_launchpad_' + config.PROJECT_ID),
}, action) => {
    switch (action.type) {
    case ACCESS_TOKEN_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ACCESS_TOKEN_FETCH_SUCCESS:
    case VERIFY_ACCOUNT_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case ACCESS_TOKEN_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

export default combineReducers({
    bc: BCDetails,
    ibc: IBCTokens,
    feeGrant,
    wallet,
    token,
});
