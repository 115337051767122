import React from 'react';
import { Button } from '@mui/material';
import logo from '../../assets/keplr.png';
import variables from '../../utils/variables';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { aminoSignTx, initializeChain, setKeplrAccountAddress } from '../../actions/account/wallet';
import { fetchAllowances, fetchBalance } from '../../actions/account/BCDetails';
import { config, DEFAULT_LIMIT, DEFAULT_SKIP } from '../../config';
import { connectBCAccount, verifyAccount } from '../../actions/account';
import { fetchTransactions, fetchTransfers, setPricePerNFT, setSaleType } from '../../actions/home';
import { fetchMintLimit, fetchMintQueue, fetchProject } from '../../actions/mint';
import moment from 'moment';

const KeplrButton = (props) => {
    const initKeplr = () => {
        props.initializeChain((address) => {
            if (address.length && address[0] && address[0].address) {
                const data = {
                    bcAccountAddress: address[0].address,
                };
                props.connectBCAccount(data, (res) => {
                    if (res) {
                        if (window.keplr) {
                            window.keplr.defaultOptions = {
                                sign: {
                                    preferNoSetFee: true,
                                    preferNoSetMemo: true,
                                },
                            };
                        }
                        const tx = {
                            msg: {
                                type: 'omniflix/MsgSign',
                                value: {
                                    address: address[0].address,
                                },
                            },
                            fee: {
                                amount: [{
                                    amount: String(0),
                                    denom: config.COIN_MINIMAL_DENOM,
                                }],
                                gas: String(1),
                            },
                            preferNoSetFee: true,
                            memo: res['auth_code'],
                        };

                        props.aminoSignTx(tx, address[0].address, (result) => {
                            if (result) {
                                const data = {
                                    authCode: res['auth_code'],
                                    sign: result.signature,
                                };

                                props.verifyAccount(res._id, data, (error) => {
                                    if (!error) {
                                        if (window.keplr) {
                                            window.keplr.defaultOptions = {};
                                        }

                                        localStorage.setItem('launchpad_of_address_' + config.PROJECT_ID, address[0].address);
                                        props.setKeplrAccountAddress(address[0].address);
                                        props.fetchBalance(address[0].address);
                                        props.fetchAllowances(address[0].address);
                                        props.fetchProject(config.PROJECT_ID, (projectResult) => {
                                            if (projectResult) {
                                                const data = projectResult && projectResult['pre_sale'] &&
                                                projectResult['pre_sale']['end_time'] &&
                                                moment().diff(projectResult['pre_sale']['end_time']) < 0
                                                    ? projectResult['pre_sale'] : projectResult;
                                                const denom = projectResult && projectResult.price && projectResult.price.denom;
                                                const ibcToken = denom && props.ibcTokensList && props.ibcTokensList.length &&
                                                    props.ibcTokensList.find((val) => val && val.ibc_denom_hash && (val.ibc_denom_hash === denom));
                                                const decimals = ibcToken && ibcToken.network && ibcToken.network.decimals
                                                    ? ibcToken.network.decimals : config.COIN_DECIMALS;
                                                let value = data && data.price;
                                                value = value && value.amount && value.amount / (10 ** decimals);

                                                props.setPricePerNFT(value);
                                                if (data['pre_sale']) {
                                                    props.setSaleType('public');
                                                } else {
                                                    props.setSaleType('pre');
                                                }
                                            }
                                        });
                                        props.fetchMintLimit(config.PROJECT_ID);
                                        props.fetchTransactions(DEFAULT_SKIP, DEFAULT_LIMIT);
                                        props.fetchTransfers(DEFAULT_SKIP, DEFAULT_LIMIT);
                                        props.fetchMintQueue((queueResult) => {
                                            if (queueResult && queueResult.length) {
                                                const time = setInterval(() => {
                                                    props.fetchMintQueue((queueResult) => {
                                                        if (props.mintQueue && queueResult &&
                                                            props.mintQueue.length !== queueResult.length) {
                                                            props.fetchTransactions(props.transactionsSkip, props.transactionsLimit);
                                                            props.fetchTransfers(props.transfersSkip, props.transfersLimit);
                                                        }
                                                        if (queueResult && !queueResult.length) {
                                                            clearInterval(time);
                                                            props.fetchTransactions(props.transactionsSkip, props.transactionsLimit);
                                                            props.fetchTransfers(props.transfersSkip, props.transfersLimit);
                                                        }
                                                    });
                                                }, 5000);
                                            }
                                        });
                                    }
                                });
                            }
                        });
                    }
                });
            }
        });
    };

    const inProgress = props.inProgress;

    return (
        <div className="keplr_button">
            <Button disabled={inProgress} variant="contained" onClick={initKeplr}>
                <img alt="keplr" src={logo}/>
                {inProgress
                    ? variables[props.lang]['approval_pending'] + '...'
                    : variables[props.lang]['connect_keplr']}
            </Button>
        </div>
    );
};

KeplrButton.propTypes = {
    address: PropTypes.string.isRequired,
    aminoSignTx: PropTypes.func.isRequired,
    connectBCAccount: PropTypes.func.isRequired,
    fetchAllowances: PropTypes.func.isRequired,
    fetchBalance: PropTypes.func.isRequired,
    fetchMintLimit: PropTypes.func.isRequired,
    fetchMintQueue: PropTypes.func.isRequired,
    fetchProject: PropTypes.func.isRequired,
    fetchTransactions: PropTypes.func.isRequired,
    fetchTransfers: PropTypes.func.isRequired,
    ibcTokensList: PropTypes.array.isRequired,
    ibcTokensListInProgress: PropTypes.bool.isRequired,
    inProgress: PropTypes.bool.isRequired,
    initializeChain: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    mintQueue: PropTypes.array.isRequired,
    setKeplrAccountAddress: PropTypes.func.isRequired,
    setPricePerNFT: PropTypes.func.isRequired,
    setSaleType: PropTypes.func.isRequired,
    verifyAccount: PropTypes.func.isRequired,
    transactionsLimit: PropTypes.number,
    transactionsSkip: PropTypes.number,
    transfersLimit: PropTypes.number,
    transfersSkip: PropTypes.number,
};

const stateToProps = (state) => {
    return {
        address: state.account.wallet.connection.address,
        ibcTokensList: state.account.ibc.ibcTokensList.value,
        ibcTokensListInProgress: state.account.ibc.ibcTokensList.inProgress,
        inProgress: state.account.wallet.connection.inProgress,
        mintQueue: state.mint.mintQueue.value,
        lang: state.language,
        transactionsLimit: state.home.transactions.limit,
        transactionsSkip: state.home.transactions.skip,
        transfersLimit: state.home.transfers.limit,
        transfersSkip: state.home.transfers.skip,
    };
};

const actionToProps = {
    aminoSignTx,
    connectBCAccount,
    fetchAllowances,
    fetchBalance,
    fetchMintLimit,
    fetchMintQueue,
    fetchProject,
    fetchTransactions,
    fetchTransfers,
    initializeChain,
    setKeplrAccountAddress,
    setPricePerNFT,
    setSaleType,
    verifyAccount,
};

export default connect(stateToProps, actionToProps)(KeplrButton);
