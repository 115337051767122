export const SEND_TOKENS_IN_PROGRESS = 'SEND_TOKENS_IN_PROGRESS';
export const SEND_TOKENS_SUCCESS = 'SEND_TOKENS_SUCCESS';
export const SEND_TOKENS_ERROR = 'SEND_TOKENS_ERROR';

export const SEND_TOKENS_IN_PROGRESS_SET = 'SEND_TOKENS_IN_PROGRESS_SET';

export const PROJECT_FETCH_IN_PROGRESS = 'PROJECT_FETCH_IN_PROGRESS';
export const PROJECT_FETCH_SUCCESS = 'PROJECT_FETCH_SUCCESS';
export const PROJECT_FETCH_ERROR = 'PROJECT_FETCH_ERROR';

export const MINT_LIMIT_FETCH_IN_PROGRESS = 'MINT_LIMIT_FETCH_IN_PROGRESS';
export const MINT_LIMIT_FETCH_SUCCESS = 'MINT_LIMIT_FETCH_SUCCESS';
export const MINT_LIMIT_FETCH_ERROR = 'MINT_LIMIT_FETCH_ERROR';

export const MINT_REQUEST_ADD_IN_PROGRESS = 'MINT_REQUEST_ADD_IN_PROGRESS';
export const MINT_REQUEST_ADD_SUCCESS = 'MINT_REQUEST_ADD_SUCCESS';
export const MINT_REQUEST_ADD_ERROR = 'MINT_REQUEST_ADD_ERROR';

export const MINT_QUEUE_FETCH_IN_PROGRESS = 'MINT_QUEUE_FETCH_IN_PROGRESS';
export const MINT_QUEUE_FETCH_SUCCESS = 'MINT_QUEUE_FETCH_SUCCESS';
export const MINT_QUEUE_FETCH_ERROR = 'MINT_QUEUE_FETCH_ERROR';

export const INTERVAL_TIME_SET = 'INTERVAL_TIME_SET';
