import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import KeplrButton from './KeplrButton';
import React from 'react';
import flixToken from '../../assets/mini-logo.svg';
import variables from '../../utils/variables';
import CopyButton from '../../components/CopyButton';
import { config, NUCLEUS_URL } from '../../config';
import DotsLoading from '../../components/DotsLoading';
import { Button, IconButton } from '@mui/material';
import { ReactComponent as Disconnect } from '../../assets/disconnect.svg';
import { setDisconnect } from '../../actions/account/wallet';
import moment from 'moment';
import { showSidePanel } from '../../actions/home';
import ClassNames from 'classnames';
import arrow from '../../assets/re_direct_arrow.png';
import arrowWhite from '../../assets/re_direct_arrow_white.png';

const ChainIDCard = (props) => {
    const handleDisconnect = () => {
        props.setDisconnect();
        localStorage.removeItem('acToken_of_launchpad_' + config.PROJECT_ID);
        localStorage.removeItem('rfToken_of_launchpad_' + config.PROJECT_ID);
        localStorage.removeItem('launchpad_of_address_' + config.PROJECT_ID);
    };

    const preSale = !!(props.project && props.project['pre_sale'] && props.project['pre_sale']['start_time'] &&
        moment().diff(props.project['pre_sale']['start_time']) > 0 && props.project['pre_sale']['end_time'] &&
        moment().diff(props.project['pre_sale']['end_time']) < 0);

    return (
        <div className="chain_id">
            {props.address === '' && !localStorage.getItem('launchpad_of_address_' + config.PROJECT_ID)
                ? <KeplrButton/>
                : props.inProgress
                    ? <p>{variables[props.lang].connecting + '...'}</p>
                    : <div>
                        <div className="header">
                            <div>
                                <h3>
                                    <img alt="icon" src={flixToken}/>
                                    {variables[props.lang]['omniflix_network']}
                                </h3>
                                <p>Chain : {config.CHAIN_ID}</p>
                            </div>
                            <IconButton className="disconnect_button" title="Disconnect" onClick={handleDisconnect}>
                                <Disconnect/>
                            </IconButton>
                        </div>
                        {props.inProgress || props.address === ''
                            ? <DotsLoading/>
                            : <div className="address_div">
                                <div className="address_content">
                                    <div className="hash_text" title={props.address}>
                                        <p className="name">{props.address}</p>
                                        {props.address.slice(props.address.length - 6, props.address.length)}
                                    </div>
                                    <CopyButton data={props.address}/>
                                </div>
                            </div>}
                        {!props.hideButton && <div className="buttons_section">
                            <a
                                href={props.project && props.project.denom && props.project.denom.id
                                    ? `${NUCLEUS_URL}/c/${props.project.denom.id}`
                                    : props.address ? `${NUCLEUS_URL}/account/${props.address}` : NUCLEUS_URL}
                                rel="noopener noreferrer" target="_blank">
                                <Button className="secondary_button">
                                    <span>{variables[props.lang]['view_marketplace']}</span>
                                    <img alt="arrow" src={arrow}/>
                                </Button>
                            </a>
                            <Button
                                className={ClassNames(props.mintQueue && props.mintQueue.length
                                    ? 'queue_button' : 'secondary_button')}
                                onClick={props.showSidePanel}>
                                {props.mintQueue && props.mintQueue.length
                                    ? <span>{variables[props.lang]['in_queue'] + ' (' + props.mintQueue.length + ')'}</span>
                                    : <span>{variables[props.lang]['view_txs']}</span>}
                                {props.mintQueue && props.mintQueue.length
                                    ? <img alt="arrow" src={arrowWhite}/>
                                    : <img alt="arrow" src={arrow}/>}
                            </Button>
                        </div>}
                        {preSale && props.mintLimitMsg === 'Address not whitelisted.' &&
                        <div className="whitelisted">
                            {variables[props.lang]['not_whitelisted']}
                        </div>}
                    </div>}
        </div>
    );
};

ChainIDCard.propTypes = {
    address: PropTypes.string.isRequired,
    allowances: PropTypes.array.isRequired,
    allowancesInProgress: PropTypes.bool.isRequired,
    balance: PropTypes.array.isRequired,
    balanceInProgress: PropTypes.bool.isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    mintQueue: PropTypes.array.isRequired,
    project: PropTypes.object.isRequired,
    setDisconnect: PropTypes.func.isRequired,
    showSidePanel: PropTypes.func.isRequired,
    hideButton: PropTypes.bool,
    mintLimitMsg: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        address: state.account.wallet.connection.address,
        allowances: state.account.bc.allowances.value,
        allowancesInProgress: state.account.bc.allowances.inProgress,
        balance: state.account.bc.balance.value,
        balanceInProgress: state.account.bc.balance.inProgress,
        inProgress: state.account.wallet.connection.inProgress,
        lang: state.language,
        mintLimitMsg: state.mint.mintLimit.message,
        mintQueue: state.mint.mintQueue.value,
        project: state.mint.project.value,
    };
};

const actionToProps = {
    setDisconnect,
    showSidePanel,
};

export default connect(stateToProps, actionToProps)(ChainIDCard);
