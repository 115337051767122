import { SigningStargateClient } from '@cosmjs/stargate';
import { config } from '../config';
import {
    INTERVAL_TIME_SET,
    MINT_LIMIT_FETCH_ERROR,
    MINT_LIMIT_FETCH_IN_PROGRESS,
    MINT_LIMIT_FETCH_SUCCESS,
    MINT_QUEUE_FETCH_ERROR,
    MINT_QUEUE_FETCH_IN_PROGRESS,
    MINT_QUEUE_FETCH_SUCCESS,
    MINT_REQUEST_ADD_ERROR,
    MINT_REQUEST_ADD_IN_PROGRESS,
    MINT_REQUEST_ADD_SUCCESS,
    PROJECT_FETCH_ERROR,
    PROJECT_FETCH_IN_PROGRESS,
    PROJECT_FETCH_SUCCESS,
    SEND_TOKENS_ERROR,
    SEND_TOKENS_IN_PROGRESS,
    SEND_TOKENS_IN_PROGRESS_SET,
    SEND_TOKENS_SUCCESS,
} from '../constants/mint';
import Axios from 'axios';
import { MINT_QUEUE_FETCH_URL, urlAddMintRequest, urlFetchMintLimit, urlFetchProject } from '../constants/url';

const sendTokensInProgress = () => {
    return {
        type: SEND_TOKENS_IN_PROGRESS,
    };
};

const sendTokensSuccess = (value) => {
    return {
        type: SEND_TOKENS_SUCCESS,
        value,
        message: 'Success',
        variant: 'success',
    };
};

const sendTokensError = (message) => {
    return {
        type: SEND_TOKENS_ERROR,
        message,
        variant: 'error',
    };
};

export const sendTokens = (tx, address, cb) => (dispatch) => {
    dispatch(sendTokensInProgress());

    (async () => {
        await window.keplr && window.keplr.enable(config.CHAIN_ID);
        const offlineSigner = window.getOfflineSignerOnlyAmino && window.getOfflineSignerOnlyAmino(config.CHAIN_ID);
        const client = await SigningStargateClient.connectWithSigner(
            config.RPC_URL,
            offlineSigner,
        );

        client.signAndBroadcast(
            address,
            tx.msgs ? tx.msgs : [tx.msg],
            tx.fee,
            tx.memo,
        ).then((result) => {
            if (result && result.code !== undefined && result.code !== 0) {
                dispatch(sendTokensError(result.log || result.rawLog));
                cb(null);
            } else {
                dispatch(sendTokensSuccess(result));
                cb(result);
            }
        }).catch((error) => {
            dispatch(sendTokensError(error && error.message));
            cb(null);
        });
    })();
};

export const setSendTokensInProgress = (value) => {
    return {
        type: SEND_TOKENS_IN_PROGRESS_SET,
        value,
    };
};

const fetchProjectInProgress = () => {
    return {
        type: PROJECT_FETCH_IN_PROGRESS,
    };
};

const fetchProjectSuccess = (value) => {
    return {
        type: PROJECT_FETCH_SUCCESS,
        value,
    };
};

const fetchProjectError = (message) => {
    return {
        type: PROJECT_FETCH_ERROR,
        message,
    };
};

export const fetchProject = (id, cb) => (dispatch) => {
    dispatch(fetchProjectInProgress());

    const url = urlFetchProject(id);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
        },
    })
        .then((res) => {
            dispatch(fetchProjectSuccess(res.data && res.data.result));
            if (cb) {
                cb(res.data && res.data.result);
            }
        })
        .catch((error) => {
            dispatch(fetchProjectError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            if (cb) {
                cb(null);
            }
        });
};

const fetchMintLimitInProgress = () => {
    return {
        type: MINT_LIMIT_FETCH_IN_PROGRESS,
    };
};

const fetchMintLimitSuccess = (value) => {
    return {
        type: MINT_LIMIT_FETCH_SUCCESS,
        value,
    };
};

const fetchMintLimitError = (message) => {
    return {
        type: MINT_LIMIT_FETCH_ERROR,
        message,
        variant: 'error',
    };
};

export const fetchMintLimit = (id) => (dispatch) => {
    dispatch(fetchMintLimitInProgress());

    const url = urlFetchMintLimit(id);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
            Authorization: 'Bearer ' + localStorage.getItem('acToken_of_launchpad_' + config.PROJECT_ID),
        },
    })
        .then((res) => {
            dispatch(fetchMintLimitSuccess(res.data && res.data.result));
        })
        .catch((error) => {
            dispatch(fetchMintLimitError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const addMintRequestInProgress = () => {
    return {
        type: MINT_REQUEST_ADD_IN_PROGRESS,
    };
};

const addMintRequestSuccess = (value, message, variant) => {
    return {
        type: MINT_REQUEST_ADD_SUCCESS,
        value,
        message,
        variant,
    };
};

const addMintRequestError = (message) => {
    return {
        type: MINT_REQUEST_ADD_ERROR,
        message,
        variant: 'error',
    };
};

export const addMintRequest = (data, id, cb) => (dispatch) => {
    dispatch(addMintRequestInProgress());

    const url = urlAddMintRequest(id);
    Axios.post(url, data, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
            Authorization: 'Bearer ' + localStorage.getItem('acToken_of_launchpad_' + config.PROJECT_ID),
        },
    })
        .then((res) => {
            dispatch(addMintRequestSuccess(res.data && res.data.result, 'Mint Request Successful.', 'success'));
            cb(res.data && res.data.result);
        })
        .catch((error) => {
            dispatch(addMintRequestError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            cb(null);
        });
};

const fetchMintQueueInProgress = () => {
    return {
        type: MINT_QUEUE_FETCH_IN_PROGRESS,
    };
};

const fetchMintQueueSuccess = (value) => {
    return {
        type: MINT_QUEUE_FETCH_SUCCESS,
        value,
    };
};

const fetchMintQueueError = (message) => {
    return {
        type: MINT_QUEUE_FETCH_ERROR,
        message,
    };
};

export const fetchMintQueue = (cb) => (dispatch) => {
    dispatch(fetchMintQueueInProgress());

    Axios.get(MINT_QUEUE_FETCH_URL, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
            Authorization: 'Bearer ' + localStorage.getItem('acToken_of_launchpad_' + config.PROJECT_ID),
        },
    })
        .then((res) => {
            dispatch(fetchMintQueueSuccess(res.data && res.data.result));
            if (cb) {
                cb(res.data && res.data.result);
            }
        })
        .catch((error) => {
            dispatch(fetchMintQueueError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            if (cb) {
                cb(null);
            }
        });
};

export const setIntervalTime = (value) => {
    return {
        type: INTERVAL_TIME_SET,
        value,
    };
};
