import { combineReducers } from 'redux';
import {
    INTERVAL_TIME_SET,
    MINT_LIMIT_FETCH_ERROR,
    MINT_LIMIT_FETCH_IN_PROGRESS,
    MINT_LIMIT_FETCH_SUCCESS,
    MINT_QUEUE_FETCH_ERROR,
    MINT_QUEUE_FETCH_IN_PROGRESS,
    MINT_QUEUE_FETCH_SUCCESS,
    MINT_REQUEST_ADD_ERROR,
    MINT_REQUEST_ADD_IN_PROGRESS,
    MINT_REQUEST_ADD_SUCCESS,
    PROJECT_FETCH_ERROR,
    PROJECT_FETCH_IN_PROGRESS,
    PROJECT_FETCH_SUCCESS,
    SEND_TOKENS_ERROR,
    SEND_TOKENS_IN_PROGRESS,
    SEND_TOKENS_IN_PROGRESS_SET,
    SEND_TOKENS_SUCCESS,
} from '../constants/mint';
import { DISCONNECT_SET } from '../constants/wallet';

const sendTokens = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case SEND_TOKENS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case SEND_TOKENS_SUCCESS:
        return {
            ...state,
            inProgress: false,
            value: action.value,
        };
    case SEND_TOKENS_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case SEND_TOKENS_IN_PROGRESS_SET:
        return {
            ...state,
            inProgress: action.value,
        };
    default:
        return state;
    }
};

const project = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case PROJECT_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case PROJECT_FETCH_SUCCESS:
        return {
            ...state,
            inProgress: false,
            value: action.value,
        };
    case PROJECT_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const mintLimit = (state = {
    inProgress: false,
    value: '',
    message: '',
}, action) => {
    switch (action.type) {
    case MINT_LIMIT_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case MINT_LIMIT_FETCH_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case MINT_LIMIT_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
            message: action.message,
        };
    case DISCONNECT_SET:
        return {
            ...state,
            value: '',
            message: '',
        };
    default:
        return state;
    }
};

const mintRequest = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case MINT_REQUEST_ADD_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case MINT_REQUEST_ADD_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case MINT_REQUEST_ADD_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const mintQueue = (state = {
    inProgress: false,
    value: [],
}, action) => {
    switch (action.type) {
    case MINT_QUEUE_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case MINT_QUEUE_FETCH_SUCCESS:
        return {
            ...state,
            inProgress: false,
            value: action.value,
        };
    case MINT_QUEUE_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const intervalTime = (state = null, action) => {
    if (action.type === INTERVAL_TIME_SET) {
        return action.value;
    }

    return state;
};

export default combineReducers({
    sendTokens,
    project,
    mintLimit,
    mintRequest,
    mintQueue,
    intervalTime,
});
