import React, { Component } from 'react';
import './index.css';
import Section1 from './Section1';
import Section2 from './Section2';
import Section3 from './Section3';
import * as PropTypes from 'prop-types';
import { fetchAllowances, fetchBalance } from '../../actions/account/BCDetails';
import { aminoSignTx, initializeChain, setDisconnect, setKeplrAccountAddress } from '../../actions/account/wallet';
import { connect } from 'react-redux';
import { config, DEFAULT_LIMIT, DEFAULT_SKIP } from '../../config';
import { connectBCAccount, verifyAccount } from '../../actions/account';
import { fetchMintLimit, fetchMintQueue, fetchProject, setIntervalTime } from '../../actions/mint';
import {
    fetchTransactions,
    fetchTransfers,
    setPricePerNFT,
    setSaleType,
    showDisclaimerDialog,
} from '../../actions/home';
import moment from 'moment';
import SidePanel from './SidePanel';
import Footer from './Footer';
import { fetchIBCTokensList } from '../../actions/account/IBCTokens';
import ServerDown from '../ServerDown';
import CircularProgress from '../../components/CircularProgress';

class Home extends Component {
    constructor (props) {
        super(props);

        this.initKeplr = this.initKeplr.bind(this);
        this.initializeKeplr = this.initializeKeplr.bind(this);
    }

    componentDidMount () {
        if (this.props.ibcTokensList && !this.props.ibcTokensList.length && !this.props.ibcTokensListInProgress) {
            this.props.fetchIBCTokensList((result) => {
                if (this.props.address === '' && localStorage.getItem('launchpad_of_address_' + config.PROJECT_ID)) {
                    setTimeout(() => {
                        this.initializeKeplr();
                    }, 600);
                } else {
                    this.props.fetchProject(config.PROJECT_ID, (projectResult) => {
                        if (projectResult) {
                            const data = projectResult && projectResult['pre_sale'] &&
                            projectResult['pre_sale']['end_time'] &&
                            moment().diff(projectResult['pre_sale']['end_time']) < 0
                                ? projectResult['pre_sale'] : projectResult;
                            const denom = projectResult && projectResult.price && projectResult.price.denom;
                            const ibcToken = denom && this.props.ibcTokensList && this.props.ibcTokensList.length &&
                                this.props.ibcTokensList.find((val) => val && val.ibc_denom_hash && (val.ibc_denom_hash === denom));
                            const decimals = ibcToken && ibcToken.network && ibcToken.network.decimals
                                ? ibcToken.network.decimals : config.COIN_DECIMALS;
                            let value = data && data.price;
                            value = value && value.amount && value.amount / (10 ** decimals);

                            this.props.setPricePerNFT(value);
                            if (data['pre_sale']) {
                                this.props.setSaleType('public');
                            } else {
                                this.props.setSaleType('pre');
                            }
                        }
                    });
                }
            });
        } else {
            if (this.props.address === '' && localStorage.getItem('launchpad_of_address_' + config.PROJECT_ID)) {
                setTimeout(() => {
                    this.initializeKeplr();
                }, 600);
            } else {
                this.props.fetchProject(config.PROJECT_ID, (projectResult) => {
                    if (projectResult) {
                        const data = projectResult && projectResult['pre_sale'] &&
                        projectResult['pre_sale']['end_time'] &&
                        moment().diff(projectResult['pre_sale']['end_time']) < 0
                            ? projectResult['pre_sale'] : projectResult;
                        const denom = projectResult && projectResult.price && projectResult.price.denom;
                        const ibcToken = denom && this.props.ibcTokensList && this.props.ibcTokensList.length &&
                            this.props.ibcTokensList.find((val) => val && val.ibc_denom_hash && (val.ibc_denom_hash === denom));
                        const decimals = ibcToken && ibcToken.network && ibcToken.network.decimals
                            ? ibcToken.network.decimals : config.COIN_DECIMALS;
                        let value = data && data.price;
                        value = value && value.amount && value.amount / (10 ** decimals);

                        this.props.setPricePerNFT(value);
                        if (data['pre_sale']) {
                            this.props.setSaleType('public');
                        } else {
                            this.props.setSaleType('pre');
                        }
                    }
                });
            }
        }

        if (!localStorage.getItem('of_launchpad_' + config.PROJECT_ID + '_disclaimer')) {
            this.props.showDisclaimerDialog();
        }

        window.addEventListener('keplr_keystorechange', () => {
            this.props.setDisconnect();
            localStorage.removeItem('acToken_of_launchpad_' + config.PROJECT_ID);
            localStorage.removeItem('rfToken_of_launchpad_' + config.PROJECT_ID);
            localStorage.removeItem('launchpad_of_address_' + config.PROJECT_ID);
            this.initKeplr();
        });
    }

    componentDidUpdate (pp, ps, ss) {
        if (this.props.auth && (pp.auth !== this.props.auth) && this.props.project && this.props.project._id) {
            setTimeout(() => {
                this.props.fetchMintLimit(this.props.project._id);
            }, 400);
        }
    }

    componentWillUnmount () {
        window.removeEventListener('keplr_keystorechange', this.initKeplr);
    }

    initKeplr () {
        this.props.initializeChain((address) => {
            if (address.length && address[0] && address[0].address) {
                const data = {
                    bcAccountAddress: address[0].address,
                };
                this.props.connectBCAccount(data, (res) => {
                    if (res) {
                        if (window.keplr) {
                            window.keplr.defaultOptions = {
                                sign: {
                                    preferNoSetFee: true,
                                    preferNoSetMemo: true,
                                },
                            };
                        }
                        const tx = {
                            msg: {
                                type: 'omniflix/MsgSign',
                                value: {
                                    address: address[0].address,
                                },
                            },
                            fee: {
                                amount: [{
                                    amount: String(0),
                                    denom: config.COIN_MINIMAL_DENOM,
                                }],
                                gas: String(1),
                            },
                            preferNoSetFee: true,
                            memo: res['auth_code'],
                        };

                        this.props.aminoSignTx(tx, address[0].address, (result) => {
                            if (result) {
                                const data = {
                                    authCode: res['auth_code'],
                                    sign: result.signature,
                                };

                                this.props.verifyAccount(res._id, data, (error) => {
                                    if (!error) {
                                        if (window.keplr) {
                                            window.keplr.defaultOptions = {};
                                        }

                                        localStorage.setItem('launchpad_of_address_' + config.PROJECT_ID, address[0].address);
                                        this.props.setKeplrAccountAddress(address[0].address);
                                        this.props.fetchBalance(address[0].address);
                                        this.props.fetchAllowances(address[0].address);
                                        this.props.fetchProject(config.PROJECT_ID, (projectResult) => {
                                            if (projectResult) {
                                                const data = projectResult && projectResult['pre_sale'] &&
                                                projectResult['pre_sale']['end_time'] &&
                                                moment().diff(projectResult['pre_sale']['end_time']) < 0
                                                    ? projectResult['pre_sale'] : projectResult;
                                                const denom = projectResult && projectResult.price && projectResult.price.denom;
                                                const ibcToken = denom && this.props.ibcTokensList && this.props.ibcTokensList.length &&
                                                    this.props.ibcTokensList.find((val) => val && val.ibc_denom_hash && (val.ibc_denom_hash === denom));
                                                const decimals = ibcToken && ibcToken.network && ibcToken.network.decimals
                                                    ? ibcToken.network.decimals : config.COIN_DECIMALS;
                                                let value = data && data.price;
                                                value = value && value.amount && value.amount / (10 ** decimals);

                                                this.props.setPricePerNFT(value);
                                                if (data['pre_sale']) {
                                                    this.props.setSaleType('public');
                                                } else {
                                                    this.props.setSaleType('pre');
                                                }
                                            }
                                        });
                                        this.props.fetchMintLimit(config.PROJECT_ID);
                                        this.props.fetchTransactions(DEFAULT_SKIP, DEFAULT_LIMIT);
                                        this.props.fetchTransfers(DEFAULT_SKIP, DEFAULT_LIMIT);
                                        this.props.fetchMintQueue((queueResult) => {
                                            if (queueResult && queueResult.length) {
                                                const time = setInterval(() => {
                                                    this.props.fetchMintQueue((queueResult) => {
                                                        if (this.props.mintQueue && queueResult &&
                                                            this.props.mintQueue.length !== queueResult.length) {
                                                            this.props.fetchTransactions(this.props.transactionsSkip, this.props.transactionsLimit);
                                                            this.props.fetchTransfers(this.props.transfersSkip, this.props.transfersLimit);
                                                            this.props.fetchProject(config.PROJECT_ID);
                                                        }
                                                        if (queueResult && !queueResult.length) {
                                                            clearInterval(time);
                                                            this.props.fetchTransactions(this.props.transactionsSkip, this.props.transactionsLimit);
                                                            this.props.fetchTransfers(this.props.transfersSkip, this.props.transfersLimit);
                                                            this.props.fetchProject(config.PROJECT_ID);
                                                        }
                                                    });
                                                }, 5000);
                                                this.props.setIntervalTime(time);
                                            }
                                        });
                                    }
                                });
                            }
                        });
                    }
                });
            }
        });
    }

    initializeKeplr () {
        this.props.initializeChain((address) => {
            if (!address) {
                window.onload = () => this.initializeKeplr();
                return;
            }

            if (address.length && address[0] && address[0].address) {
                this.props.setKeplrAccountAddress(address[0].address);
                if ((this.props.balance.length === 0) && !this.props.balanceInProgress) {
                    this.props.fetchBalance(address[0].address);
                }
                if (this.props.allowances && (this.props.allowances.length === 0) &&
                    !this.props.allowancesInProgress) {
                    this.props.fetchAllowances(address[0].address);
                }
                this.props.fetchProject(config.PROJECT_ID, (projectResult) => {
                    if (projectResult) {
                        const data = projectResult && projectResult['pre_sale'] &&
                        projectResult['pre_sale']['end_time'] &&
                        moment().diff(projectResult['pre_sale']['end_time']) < 0
                            ? projectResult['pre_sale'] : projectResult;
                        const denom = projectResult && projectResult.price && projectResult.price.denom;
                        const ibcToken = denom && this.props.ibcTokensList && this.props.ibcTokensList.length &&
                            this.props.ibcTokensList.find((val) => val && val.ibc_denom_hash && (val.ibc_denom_hash === denom));
                        const decimals = ibcToken && ibcToken.network && ibcToken.network.decimals
                            ? ibcToken.network.decimals : config.COIN_DECIMALS;
                        let value = data && data.price;
                        value = value && value.amount && value.amount / (10 ** decimals);

                        this.props.setPricePerNFT(value);
                        if (data['pre_sale']) {
                            this.props.setSaleType('public');
                        } else {
                            this.props.setSaleType('pre');
                        }
                    }
                });
                this.props.fetchMintLimit(config.PROJECT_ID);
                this.props.fetchTransactions(DEFAULT_SKIP, DEFAULT_LIMIT);
                this.props.fetchTransfers(DEFAULT_SKIP, DEFAULT_LIMIT);
                this.props.fetchMintQueue((queueResult) => {
                    if (queueResult && queueResult.length) {
                        const time = setInterval(() => {
                            this.props.fetchMintQueue((queueResult) => {
                                if (this.props.mintQueue && queueResult &&
                                    this.props.mintQueue.length !== queueResult.length) {
                                    this.props.fetchTransactions(this.props.transactionsSkip, this.props.transactionsLimit);
                                    this.props.fetchTransfers(this.props.transfersSkip, this.props.transfersLimit);
                                    this.props.fetchProject(config.PROJECT_ID);
                                }
                                if (queueResult && !queueResult.length) {
                                    clearInterval(time);
                                    this.props.fetchTransactions(this.props.transactionsSkip, this.props.transactionsLimit);
                                    this.props.fetchTransfers(this.props.transfersSkip, this.props.transfersLimit);
                                    this.props.fetchProject(config.PROJECT_ID);
                                }
                            });
                        }, 5000);
                        this.props.setIntervalTime(time);
                    }
                });
            }
        });
    }

    render () {
        return (
            <div className="home">
                {this.props.projectInProgress || this.props.ibcTokensListInProgress ||
                this.props.inProgress || (this.props.ibcTokensList && this.props.ibcTokensList.length &&
                    this.props.project && !this.props.project._id)
                    ? <CircularProgress className="full_screen"/>
                    : this.props.project && this.props.project._id
                        ? <>
                            <Section1/>
                            <Section2/>
                            <Section3/>
                            <Footer/>
                            <SidePanel/>
                        </>
                        : <ServerDown/>}
            </div>
        );
    }
}

Home.propTypes = {
    address: PropTypes.string.isRequired,
    allowances: PropTypes.array.isRequired,
    allowancesInProgress: PropTypes.bool.isRequired,
    aminoSignTx: PropTypes.func.isRequired,
    balance: PropTypes.array.isRequired,
    balanceInProgress: PropTypes.bool.isRequired,
    connectBCAccount: PropTypes.func.isRequired,
    fetchAllowances: PropTypes.func.isRequired,
    fetchBalance: PropTypes.func.isRequired,
    fetchIBCTokensList: PropTypes.func.isRequired,
    fetchMintLimit: PropTypes.func.isRequired,
    fetchMintQueue: PropTypes.func.isRequired,
    fetchProject: PropTypes.func.isRequired,
    fetchTransactions: PropTypes.func.isRequired,
    fetchTransfers: PropTypes.func.isRequired,
    ibcTokensList: PropTypes.array.isRequired,
    ibcTokensListInProgress: PropTypes.bool.isRequired,
    inProgress: PropTypes.bool.isRequired,
    initializeChain: PropTypes.func.isRequired,
    mintQueue: PropTypes.array.isRequired,
    project: PropTypes.object.isRequired,
    projectInProgress: PropTypes.bool.isRequired,
    setDisconnect: PropTypes.func.isRequired,
    setIntervalTime: PropTypes.func.isRequired,
    setKeplrAccountAddress: PropTypes.func.isRequired,
    setPricePerNFT: PropTypes.func.isRequired,
    setSaleType: PropTypes.func.isRequired,
    showDisclaimerDialog: PropTypes.func.isRequired,
    verifyAccount: PropTypes.func.isRequired,
    auth: PropTypes.string,
    transactionsLimit: PropTypes.number,
    transactionsSkip: PropTypes.number,
    transfersLimit: PropTypes.number,
    transfersSkip: PropTypes.number,
};

const stateToProps = (state) => {
    return {
        auth: state.account.token.value,
        address: state.account.wallet.connection.address,
        allowances: state.account.bc.allowances.value,
        allowancesInProgress: state.account.bc.allowances.inProgress,
        balance: state.account.bc.balance.value,
        balanceInProgress: state.account.bc.balance.inProgress,
        ibcTokensList: state.account.ibc.ibcTokensList.value,
        ibcTokensListInProgress: state.account.ibc.ibcTokensList.inProgress,
        inProgress: state.account.wallet.connection.inProgress,
        mintQueue: state.mint.mintQueue.value,
        project: state.mint.project.value,
        projectInProgress: state.mint.project.inProgress,
        transactionsLimit: state.home.transactions.limit,
        transactionsSkip: state.home.transactions.skip,
        transfersLimit: state.home.transfers.limit,
        transfersSkip: state.home.transfers.skip,
    };
};

const actionToProps = {
    aminoSignTx,
    connectBCAccount,
    fetchAllowances,
    fetchBalance,
    fetchIBCTokensList,
    fetchMintLimit,
    fetchMintQueue,
    fetchProject,
    fetchTransactions,
    fetchTransfers,
    initializeChain,
    setDisconnect,
    setIntervalTime,
    setKeplrAccountAddress,
    setPricePerNFT,
    setSaleType,
    showDisclaimerDialog,
    verifyAccount,
};

export default connect(stateToProps, actionToProps)(Home);
