import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import TextField from '../../../components/TextField';
import { InputAdornment } from '@mui/material';
import NetworkImages from '../../../components/NetworkImages';
import { setAmountValue } from '../../../actions/home';

const AmountValueTextField = (props) => {
    const handleChange = (value) => {
        props.onChange(value);
    };

    return (
        <TextField
            className="amount_field"
            id="amount-value"
            inputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <NetworkImages alt={props.denom} name={props.denom}/>
                        <span className="token_start">{props.denom}</span>
                    </InputAdornment>
                ),
            }}
            name="amount_value"
            type="number"
            value={props.value}
            onChange={handleChange}/>
    );
};

AmountValueTextField.propTypes = {
    lang: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    balance: PropTypes.number,
    denom: PropTypes.string,
    value: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        value: state.home.amountValue,
    };
};

const actionsToProps = {
    onChange: setAmountValue,
};

export default connect(stateToProps, actionsToProps)(AmountValueTextField);
