import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import variables from '../../../utils/variables';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hideDisclaimerDialog } from '../../../actions/home';
import './index.css';
import { config } from '../../../config';

const Disclaimer = (props) => {
    const handleAgree = () => {
        props.handleClose();
        localStorage.setItem('of_launchpad_' + config.PROJECT_ID + '_disclaimer', 'true');
    };

    return (
        <Dialog
            className="dialog disclaimer_dialog"
            open={props.open}>
            <DialogTitle>
                <h2>{variables[props.lang].disclaimer}</h2>
            </DialogTitle>
            <DialogContent className="dialog_content scroll_bar">
                <p>{variables[props.lang]['disclaimer_info']}</p>
                <br/>
                <br/>
                <p>{variables[props.lang]['disclaimer_info2']}</p>
                <br/>
                <p>{variables[props.lang]['disclaimer_info3']}</p>
                <br/>
                <p>{variables[props.lang]['disclaimer_info4']}</p>
            </DialogContent>
            <DialogActions className="actions_div">
                <Button onClick={handleAgree}>
                    <span>{variables[props.lang]['agree_and_continue']}</span>
                </Button>
            </DialogActions>
        </Dialog>
    );
};

Disclaimer.propTypes = {
    handleClose: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
};

const stateToProps = (state) => {
    return {
        open: state.home.disclaimerDialog,
        lang: state.language,
    };
};

const actionToProps = {
    handleClose: hideDisclaimerDialog,
};

export default connect(stateToProps, actionToProps)(Disclaimer);
