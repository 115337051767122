import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import { hideSnackbar } from '../actions/snackbar';
import Snackbar from '../components/Snackbar';
import { setDisconnect } from '../actions/account/wallet';
import { fetchAccessToken } from '../actions/account';
import { LinearProgress, Slide, Snackbar as MaterialSnackbar } from '@mui/material';
import waitingIcon from '../assets/snackbar/waiting.svg';
import variables from '../utils/variables';

const TransitionUp = (props) => <Slide {...props} direction="left"/>;

class SnackbarMessage extends Component {
    constructor (props) {
        super(props);

        this.state = {
            message: null,
            autoHide: true,
        };
    }

    componentDidMount () {
        setTimeout(() => this.setState({
            autoHide: false,
        }), 5000);
    }

    componentDidUpdate (pp, ps, ss) {
        if (pp.message !== this.props.message) {
            if (pp.message === 'File Upload Failed! Try after sometime.' && this.props.message === 'Asset Deleted Successfully') {
                this.setState({
                    message: 'File Upload Failed! Try after sometime.',
                });

                return;
            } else if (this.state.message) {
                this.setState({
                    message: null,
                });
            }

            switch (this.props.message) {
            case 'Token is expired':
            case 'Error occurred while verifying the JWT token.':
            case 'User Id and token combination does not exist.':
                this.props.onClose();

                if (this.props.tokenInProgress) {
                    return;
                }

                return this.props.fetchAccessToken((error) => {
                    if (error) {
                        this.props.setDisconnect();
                    }
                });
            default:
                break;
            }
        }
    }

    render () {
        return (
            <div className="queue_snackbar">
                <Snackbar
                    explorer={this.props.explorer}
                    hash={this.props.hash}
                    manual={this.props.ibcTxInProgress}
                    message={this.props.message}
                    open={this.props.open}
                    progress={this.props.ibcTxInProgress}
                    variant={this.props.variant}
                    onClose={this.props.onClose}/>
                {this.props.mintQueue && this.props.mintQueue.length
                    ? this.props.mintQueue.map((value, index) => {
                        return (
                            <MaterialSnackbar
                                key={index}
                                ContentProps={{
                                    'aria-describedby': 'message-id',
                                }}
                                TransitionComponent={TransitionUp}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                autoHideDuration={5000}
                                className="snackbar"
                                message={<div>
                                    <div className="snackbar_class success_snackbar" id="message-id">
                                        <img alt="icon" src={waitingIcon}/>
                                        <span>{variables[this.props.lang]['transaction_in_queue']}</span>
                                    </div>
                                    <p>{variables[this.props.lang]['queue_number'] + ' : ' + value.position}</p>
                                    <LinearProgress className="progress_bar"/>
                                </div>}
                                open={this.state.autoHide}/>
                        );
                    })
                    : null}
            </div>
        );
    }
}

SnackbarMessage.propTypes = {
    fetchAccessToken: PropTypes.func.isRequired,
    ibcTxInProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    mintQueue: PropTypes.array.isRequired,
    open: PropTypes.bool.isRequired,
    setDisconnect: PropTypes.func.isRequired,
    tokenInProgress: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    explorer: PropTypes.string,
    hash: PropTypes.string,
    variant: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        explorer: state.snackbar.explorer,
        ibcTxInProgress: state.account.ibc.ibcTxInProgress,
        open: state.snackbar.open,
        message: state.snackbar.message,
        mintQueue: state.mint.mintQueue.value,
        variant: state.snackbar.variant,
        hash: state.snackbar.hash,
        tokenInProgress: state.account.token.inProgress,
    };
};

const actionsToProps = {
    fetchAccessToken,
    onClose: hideSnackbar,
    setDisconnect,
};

export default connect(stateToProps, actionsToProps)(SnackbarMessage);
