import { combineReducers } from 'redux';
import {
    AMINO_SIGN_SUCCESS,
    CONNECT_KEPLR_ACCOUNT_ERROR,
    CONNECT_KEPLR_ACCOUNT_IN_PROGRESS,
    CONNECT_KEPLR_ACCOUNT_SUCCESS,
    DISCONNECT_SET,
    KEPLR_ACCOUNT_ADDRESS_SET,
    TX_SIGN_AND_BROAD_CAST_ERROR,
    TX_SIGN_AND_BROAD_CAST_IN_PROGRESS,
    TX_SIGN_AND_BROAD_CAST_SUCCESS,
} from '../../constants/wallet';
import {
    KEPLR_ACCOUNT_KEYS_SET,
    VERIFY_ACCOUNT_ERROR,
    VERIFY_ACCOUNT_IN_PROGRESS,
    VERIFY_ACCOUNT_SUCCESS,
} from '../../constants/account';

const connection = (state = {
    inProgress: false,
    address: '',
    keys: {},
}, action) => {
    switch (action.type) {
    case CONNECT_KEPLR_ACCOUNT_IN_PROGRESS:
    case VERIFY_ACCOUNT_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case KEPLR_ACCOUNT_ADDRESS_SET:
        return {
            ...state,
            address: action.value,
        };
    case CONNECT_KEPLR_ACCOUNT_SUCCESS:
    case VERIFY_ACCOUNT_SUCCESS:
    case VERIFY_ACCOUNT_ERROR:
    case CONNECT_KEPLR_ACCOUNT_ERROR:
    case AMINO_SIGN_SUCCESS:
        return {
            ...state,
            inProgress: false,
        };
    case KEPLR_ACCOUNT_KEYS_SET:
        return {
            ...state,
            keys: action.value,
        };
    case DISCONNECT_SET:
        return {
            ...state,
            address: '',
            keys: {},
        };
    default:
        return state;
    }
};

const broadCast = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case TX_SIGN_AND_BROAD_CAST_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case TX_SIGN_AND_BROAD_CAST_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case TX_SIGN_AND_BROAD_CAST_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

export default combineReducers({
    broadCast,
    connection,
});
