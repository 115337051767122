import { config, STUDIO_API_URL } from '../config';

const URL = config.API_URL;
const STUDIO_URL = STUDIO_API_URL;
const PROJECT_ID = config.PROJECT_ID;

export const CONNECT_ACCOUNT_URL = `${URL}/users/connect-bc-account`;
export const FEE_GRANT_ALLOWANCE_URL = `${URL}/me/fee-grant`;
export const ACCESS_TOKEN_URL = `${URL}/user/auth/refresh-token`;
export const PROJECTS_FETCH_URL = `${URL}/projects`;
export const MINT_QUEUE_FETCH_URL = `${URL}/me/projects/${PROJECT_ID}/mint-queue`;

export const urlVerifyAccount = (userId) =>
    `${URL}/users/${userId}/verify-bc-account`;
export const urlFetchProject = (id) =>
    `${URL}/project/${id}`;
export const urlFetchMintLimit = (id) =>
    `${URL}/me/projects/${id}/mint-limit`;
export const urlAddMintRequest = (id) =>
    `${URL}/projects/${id}/mint-requests`;
export const urlFetchTransactions = (skip, limit) => {
    const params = ['sortBy=created_at', 'order=desc', 'statuses[]=COMPLETED', 'statuses[]=FAILED',
        'statuses[]=EXPIRED', 'statuses[]=INVALID', 'statuses[]=CREATED'];
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }

    return `${URL}/me/projects/${config.PROJECT_ID}/mint-requests?${params.join('&')}`;
};
export const urlFetchTransfers = (skip, limit) => {
    const params = ['sortBy=created_at', 'order=desc', 'refundStatuses[]=REFUND', 'refundStatuses[]=REFUNDED'];
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }

    return `${URL}/me/transfers?${params.join('&')}`;
};

export const IBC_TOKENS_LIST_URL = `${STUDIO_URL}/tokens`;
