import React, { Component } from 'react';
import { Button, Dialog } from '@mui/material';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import variables from '../../../utils/variables';
import AmountValueTextField from './AmountValueTextField';
import arrowIcon from '../../../assets/arrow_right.svg';
import DotsLoading from '../../../components/DotsLoading';
import { hideDepositDialog, setDepositConfirm } from '../../../actions/home';
import NetworkImages from '../../../components/NetworkImages';
import { showMessage } from '../../../actions/snackbar';
import './index.css';
import CopyButton from '../../../components/CopyButton';
import Long from 'long';
import { config } from '../../../config';
import {
    aminoSignIBCTx,
    connectIBCAccount,
    fetchIBCBalance,
    fetchTimeoutHeight,
    setIBCTxInProgress,
} from '../../../actions/account/IBCTokens';
import { fetchBalance } from '../../../actions/account/BCDetails';
import CircularProgress from '../../../components/CircularProgress';

class DepositDialog extends Component {
    constructor (props) {
        super(props);

        this.state = {
            config: {},
            hash: '',
            inProgress: false,
        };

        this.initKeplr = this.initKeplr.bind(this);
        this.handleDeposit = this.handleDeposit.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    componentDidUpdate (pp, ps, ss) {
        const denom = this.props.project && this.props.project.price && this.props.project.price.denom;
        const ibcToken = denom && this.props.ibcTokensList && this.props.ibcTokensList.length &&
            this.props.ibcTokensList.find((val) => val && val.ibc_denom_hash && (val.ibc_denom_hash === denom));

        if ((pp.open !== this.props.open) && this.props.open) {
            const config = {
                RPC_URL: ibcToken && ibcToken.network && ibcToken.network.rpc_address,
                REST_URL: ibcToken && ibcToken.network && ibcToken.network.api_address,
                CHAIN_ID: ibcToken && ibcToken.network && ibcToken.network.chain_id,
                CHAIN_NAME: ibcToken && ibcToken.network && ibcToken.network.name,
                COIN_DENOM: ibcToken && ibcToken.network && ibcToken.network.display_denom,
                COIN_MINIMAL_DENOM: ibcToken && ibcToken.network && ibcToken.network.denom,
                COIN_DECIMALS: ibcToken && ibcToken.network && ibcToken.network.decimals,
                PREFIX: ibcToken && ibcToken.network && ibcToken.network.address_prefix,
            };

            this.setState({
                config: config,
            });
            this.initKeplr(config);
        }
    }

    initKeplr (config) {
        this.props.connectIBCAccount(config, (address) => {
            this.props.fetchIBCBalance(config.REST_URL, address[0].address);
        });
    }

    handleClose () {
        this.props.handleClose();

        if (this.state.successDeposit) {
            this.setState({
                successDeposit: false,
            });
        }
    }

    handleDeposit (decimals, denom) {
        const ibcToken = denom && this.props.ibcTokensList && this.props.ibcTokensList.length &&
            this.props.ibcTokensList.find((val) => val && val.ibc_denom_hash && (val.ibc_denom_hash === denom));

        this.setState({
            inProgress: true,
        });

        this.props.fetchTimeoutHeight(config.REST_URL, ibcToken && ibcToken.channel, (result) => {
            const revisionNumber = result && result.proof_height && result.proof_height.revision_number &&
                Long.fromNumber(result.proof_height.revision_number);
            const revisionHeight = result && result.proof_height && result.proof_height.revision_height;

            const data = {
                msg: {
                    typeUrl: '/ibc.applications.transfer.v1.MsgTransfer',
                    value: {
                        source_port: 'transfer',
                        source_channel: ibcToken && ibcToken.destination_channel,
                        token: {
                            denom: ibcToken && ibcToken.network && ibcToken.network.denom,
                            amount: String(this.props.amountValue * (10 ** decimals)),
                        },
                        sender: this.props.ibcAddress,
                        receiver: this.props.address,
                        timeout_height: {
                            revisionNumber: revisionNumber || undefined,
                            revisionHeight: Long.fromNumber(parseInt(revisionHeight) + 150) || undefined,
                        },
                        timeout_timestamp: undefined,
                    },
                },
                fee: {
                    amount: [{
                        amount: String(225000),
                        denom: ibcToken && ibcToken.network && ibcToken.network.denom,
                    }],
                    gas: String(750000),
                },
                memo: '',
            };

            let balance = this.props.mainBalance && this.props.mainBalance.length && denom &&
                this.props.mainBalance.find((val) => val.denom === denom);
            balance = balance && balance.amount;
            const explorer = ibcToken && ibcToken.network && ibcToken.network.explorer;

            this.props.sign(this.state.config, data, (result) => {
                if (result) {
                    this.props.setIBCTxInProgress(true);
                    this.props.fetchIBCBalance(ibcToken && ibcToken.network && ibcToken.network.api_address,
                        this.props.ibcAddress);
                    const time = setInterval(() => {
                        this.props.fetchBalance(this.props.address, (resBalance) => {
                            let resultBalance = resBalance && resBalance.length && denom &&
                                resBalance.find((val) => val.denom === denom);
                            resultBalance = resultBalance && resultBalance.amount;
                            if (resultBalance !== balance) {
                                this.props.setIBCTxInProgress(false);
                                this.props.showMessage('Transaction Successful', 'success', result && result.transactionHash, explorer);
                                this.props.fetchBalance(this.props.address);
                                clearInterval(time);
                                this.setState({
                                    inProgress: false,
                                    successDeposit: true,
                                    hash: result && result.transactionHash,
                                });
                            }
                        });
                    }, 5000);
                } else {
                    this.setState({
                        inProgress: false,
                    });
                }
            });
        });
    }

    render () {
        const denom = this.props.project && this.props.project.price && this.props.project.price.denom;
        const ibcToken = denom && this.props.ibcTokensList && this.props.ibcTokensList.length &&
            this.props.ibcTokensList.find((val) => val && val.ibc_denom_hash && (val.ibc_denom_hash === denom));
        const decimals = ibcToken && ibcToken.network && ibcToken.network.decimals;
        let balance = this.props.balance && this.props.balance.length &&
            ibcToken && ibcToken.network && ibcToken.network.denom &&
            this.props.balance.find((val) => val.denom === ibcToken.network.denom);
        balance = balance && balance.amount && balance.amount / (10 ** decimals);

        const amountWithDecimals = this.props.amountValue && (this.props.amountValue * (10 ** decimals));
        const inProgress = this.props.accountInProgress || this.props.timeoutHeightInProgress || this.props.signInProgress;
        const disable = amountWithDecimals < 1 || this.props.amountValue > balance || inProgress;
        const displayDenom = ibcToken && ibcToken.network && ibcToken.network.display_denom;
        const hash = this.state.hash;

        return (
            <Dialog
                aria-describedby="verify-twitter-dialog-description"
                aria-labelledby="verify-twitter-dialog-title"
                className={this.state.successDeposit ? 'success_deposit_dialog dialog deposite_dialog' : 'dialog deposite_dialog'}
                open={this.props.open}
                onClose={this.handleClose}>
                {this.state.inProgress
                    ? <CircularProgress className="full_screen"/>
                    : null}
                {this.state.successDeposit
                    ? <div className="success_deposit">
                        <p>{this.props.amountValue} {displayDenom}</p>
                        <p>Deposited successfully</p>
                        <span>Transaction Hash</span>
                        <div>
                            <span>{hash}</span>
                            <CopyButton data={hash}/>
                        </div>
                    </div>
                    : this.props.confirmDeposit
                        ? <div className="confirm_ibc_dialog">
                            <h2>{variables[this.props.lang]['confirm_deposit_dialog']}</h2>
                            <div>
                                <div className="row">
                                    <span className="label">{variables[this.props.lang].message_type}</span>
                                    <p className="value">IBCdeposit</p>
                                </div>
                                <div className="row">
                                    <span className="label">{variables[this.props.lang].selected_token}</span>
                                    <p className="value token_info">
                                        <NetworkImages alt={displayDenom} name={displayDenom}/>
                                        {displayDenom}</p>
                                </div>
                                <div className="row">
                                    <span className="label">{variables[this.props.lang].amount_deposit}</span>
                                    <p className="value">{this.props.amountValue}</p>
                                </div>
                                <div className="row">
                                    <span className="label">{variables[this.props.lang].from}</span>
                                    <div className="hash_text address" title={this.props.ibcAddress}>
                                        <p className="name">{this.props.ibcAddress}</p>
                                        {this.props.ibcAddress &&
                                        this.props.ibcAddress.slice(this.props.ibcAddress.length - 6, this.props.ibcAddress.length)}
                                    </div>
                                </div>
                                <div className="row">
                                    <span className="label">{variables[this.props.lang].to}</span>
                                    <div className="hash_text address" title={this.props.address}>
                                        <p className="name">{this.props.address}</p>
                                        {this.props.address &&
                                        this.props.address.slice(this.props.address.length - 6, this.props.address.length)}
                                    </div>
                                </div>
                            </div>
                            <div className="confirm_actions">
                                <Button
                                    className="cancel_button"
                                    onClick={this.handleClose}>
                                    <p>{variables[this.props.lang].cancel}</p>
                                </Button>
                                <Button
                                    className="deposite_button confirm_button"
                                    disabled={disable}
                                    onClick={() => this.handleDeposit(decimals, denom)}>
                                    <p>
                                        {inProgress
                                            ? variables[this.props.lang]['approval_pending'] + '...'
                                            : variables[this.props.lang].confirm}
                                    </p>
                                </Button>
                            </div>
                        </div>
                        : <div className="deposite_dialog_content">
                            <h2>{variables[this.props.lang]['deposit_ibc_asset']}</h2>
                            <div className="deposite_section1">
                                <span>{variables[this.props.lang]['ibc_transfer']}</span>
                                <div className="ibc_transfer_section">
                                    <div>
                                        <span>{variables[this.props.lang].from}</span>
                                        {this.props.inProgress
                                            ? <DotsLoading/>
                                            : <div className="hash_text" title={this.props.ibcAddress}>
                                                <p className="name">{this.props.ibcAddress}</p>
                                                {this.props.ibcAddress.slice(this.props.ibcAddress.length - 4, this.props.ibcAddress.length)}
                                            </div>}
                                    </div>
                                    <img alt="icon" src={arrowIcon}/>
                                    <div>
                                        <span>{variables[this.props.lang].to}</span>
                                        <div className="hash_text" title={this.props.address}>
                                            <p className="name">{this.props.address}</p>
                                            {this.props.address.slice(this.props.address.length - 4, this.props.address.length)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="deposite_section2">
                                <div className="deposite_section2_header">
                                    <span>{variables[this.props.lang]['amount_deposit']}</span>
                                    <span className="balance"> Available = &nbsp;{this.props.balanceInProgress
                                        ? <DotsLoading/>
                                        : balance
                                            ? <span>{balance} {this.state.config && this.state.config.COIN_DENOM}</span>
                                            : `0 ${ibcToken && ibcToken.network && ibcToken.network.display_denom}`}</span>
                                </div>
                                <AmountValueTextField
                                    balance={balance || 0}
                                    denom={ibcToken && ibcToken.network && ibcToken.network.display_denom}/>
                            </div>
                            <Button
                                className="deposite_button primary_button"
                                disabled={disable}
                                onClick={() => this.props.setDepositConfirm(true)}>
                                <span>{variables[this.props.lang].deposit}</span>
                            </Button>
                        </div>}
            </Dialog>
        );
    }
}

DepositDialog.propTypes = {
    accountInProgress: PropTypes.bool.isRequired,
    address: PropTypes.string.isRequired,
    balance: PropTypes.array.isRequired,
    balanceInProgress: PropTypes.bool.isRequired,
    confirmDeposit: PropTypes.bool.isRequired,
    connectIBCAccount: PropTypes.func.isRequired,
    fetchBalance: PropTypes.func.isRequired,
    fetchIBCBalance: PropTypes.func.isRequired,
    fetchTimeoutHeight: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    ibcAddress: PropTypes.string.isRequired,
    ibcTokensList: PropTypes.array.isRequired,
    ibcTokensListInProgress: PropTypes.bool.isRequired,
    ibcTxInProgress: PropTypes.bool.isRequired,
    inProgress: PropTypes.bool.isRequired,
    keys: PropTypes.object.isRequired,
    lang: PropTypes.string.isRequired,
    mainBalance: PropTypes.array.isRequired,
    open: PropTypes.bool.isRequired,
    project: PropTypes.object.isRequired,
    setDepositConfirm: PropTypes.func.isRequired,
    setIBCTxInProgress: PropTypes.func.isRequired,
    showMessage: PropTypes.func.isRequired,
    sign: PropTypes.func.isRequired,
    signInProgress: PropTypes.bool.isRequired,
    timeoutHeightInProgress: PropTypes.bool.isRequired,
    value: PropTypes.object.isRequired,
    amountValue: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        address: state.account.wallet.connection.address,
        accountInProgress: state.account.wallet.connection.inProgress,
        amountValue: state.home.amountValue,
        confirmDeposit: state.home.depositConfirm,
        balance: state.account.ibc.balance.value,
        balanceInProgress: state.account.ibc.balance.inProgress,
        ibcAddress: state.account.ibc.connection.address,
        ibcTokensList: state.account.ibc.ibcTokensList.value,
        ibcTokensListInProgress: state.account.ibc.ibcTokensList.inProgress,
        ibcTxInProgress: state.account.ibc.ibcTxInProgress,
        inProgress: state.account.ibc.connection.inProgress,
        keys: state.account.wallet.connection.keys,
        lang: state.language,
        mainBalance: state.account.bc.balance.value,
        open: state.home.depositDialog.open,
        project: state.mint.project.value,
        signInProgress: state.account.ibc.connection.signInProgress,
        timeoutHeightInProgress: state.account.ibc.timeoutHeight.inProgress,
        value: state.home.depositDialog.value,
    };
};

const actionToProps = {
    connectIBCAccount,
    fetchBalance,
    fetchIBCBalance,
    fetchTimeoutHeight,
    handleClose: hideDepositDialog,
    setDepositConfirm,
    setIBCTxInProgress,
    showMessage,
    sign: aminoSignIBCTx,
};

export default connect(stateToProps, actionToProps)(DepositDialog);
