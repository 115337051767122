import { config } from '../config';

const URL = config.REST_URL;

export const urlFetchBalance = (address) => `${URL}/cosmos/bank/v1beta1/balances/${address}`;
export const urlFetchAllowances = (address) => `${URL}/cosmos/feegrant/v1beta1/allowances/${address}`;

export const urlFetchIBCBalance = (url, address) => `${url}/cosmos/bank/v1beta1/balances/${address}`;
export const urlFetchTimeoutHeight = (url, channel) => {
    let version = 'v1';
    if (url.indexOf('bluenet') > -1) {
        version = 'v1beta1';
    }

    return `${url}/ibc/core/channel/${version}/channels/${channel}/ports/transfer`;
};
