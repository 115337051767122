import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    fetchTransactions,
    fetchTransfers,
    hideMintNFTDialog,
    setPricePerNFT,
    successMintNFTDialog,
} from '../../../actions/home';
import { Button, Dialog } from '@mui/material';
import React from 'react';
import variables from '../../../utils/variables';
import './index.css';
import { config, DEFAULT_LIMIT, DEFAULT_SKIP, NUCLEUS_URL } from '../../../config';
import NetworkImages from '../../../components/NetworkImages';
import { showMessage } from '../../../actions/snackbar';
import {
    // aminoSignTx,
    aminoSignTxProtoTX,
    fetchTxHash,
    protoBufSigning,
    setTxHashInProgressFalse,
    txSignAndBroadCast,
    txSignAndBroadCastAminoSign,
} from '../../../actions/account/wallet';
import { connectIBCAccount } from '../../../actions/account/IBCTokens';
import { fetchAllowances, fetchBalance } from '../../../actions/account/BCDetails';
import {
    addMintRequest,
    fetchMintLimit,
    fetchMintQueue,
    fetchProject,
    setIntervalTime,
    setSendTokensInProgress,
} from '../../../actions/mint';
import { splitDecimals } from '../../../utils/numbers';
import moment from 'moment';
import CopyButton from '../../../components/CopyButton';
import CircularProgress from '../../../components/CircularProgress';
import { ReactComponent as TwitterLogo } from '../../../assets/socialLinks/x.svg';
import { TwitterShareButton } from 'react-share';

const MintNFTDialog = (props) => {
    const handleConfirm = () => {
        if (props.intervalTime) {
            clearInterval(props.intervalTime);
            props.setIntervalTime(null);
        }

        props.setSendTokensInProgress(true);
        const mintData = {
            address: props.address,
            quantity: props.value && props.value.count,
        };

        props.addMintRequest(mintData, props.project && props.project._id, (mintResult) => {
            if (mintResult) {
                const denom = props.project && props.project.price && props.project.price.denom;
                const ibcToken = denom && props.ibcTokensList && props.ibcTokensList.length &&
                    props.ibcTokensList.find((val) => val && val.ibc_denom_hash && (val.ibc_denom_hash === denom));
                const decimals = ibcToken && ibcToken.network && ibcToken.network.decimals;

                let balance = props.balance && props.balance.length && props.balance.find((val) => val.denom === config.COIN_MINIMAL_DENOM);
                balance = balance && balance.amount && splitDecimals(balance.amount / (10 ** config.COIN_DECIMALS));

                const data = {
                    msg: {
                        typeUrl: '/cosmos.bank.v1beta1.MsgSend',
                        value: {
                            from_address: props.address,
                            to_address: toAddress,
                            amount: [{
                                denom: denom,
                                amount: String(price * (10 ** decimals)),
                            }],
                        },
                    },
                    fee: {
                        amount: [{
                            amount: String(5000),
                            denom: config.COIN_MINIMAL_DENOM,
                        }],
                        gasLimit: String(200000),
                    },
                    memo: mintResult && mintResult.memo,
                };

                const type = '/cosmos.bank.v1beta1.MsgSend';
                const granterInfo = {};
                if (props.allowances && props.allowances.length) {
                    props.allowances.map((val) => {
                        if (val && val.allowance && val.allowance.spend_limit && val.allowance.spend_limit.length) {
                            const amount = val.allowance.spend_limit.find((val1) => (val1.denom === config.COIN_MINIMAL_DENOM) &&
                                val1.amount && (val1.amount > 0.1 * (10 ** config.COIN_DECIMALS)));
                            if (amount && amount.amount) {
                                granterInfo.granter = val.granter;
                                granterInfo.amount = amount.amount / 10 ** config.COIN_DECIMALS;
                            }
                        } else if (val && val.allowance && val.allowance.allowed_messages &&
                            type && val.allowance.allowed_messages.indexOf(type) > -1) {
                            if (val && val.allowance && val.allowance.allowance &&
                                val.allowance.allowance.spend_limit && val.allowance.allowance.spend_limit.length) {
                                const amount = val.allowance.allowance.spend_limit.find((val1) => (val1.denom === config.COIN_MINIMAL_DENOM) &&
                                    val1.amount && (val1.amount > 0.1 * (10 ** config.COIN_DECIMALS)));
                                if (amount && amount.amount) {
                                    granterInfo.granter = val.granter;
                                    granterInfo.amount = amount.amount / 10 ** config.COIN_DECIMALS;
                                }
                            }
                        }

                        return null;
                    });
                }

                if ((granterInfo && granterInfo.granter && !balance) ||
                    (granterInfo && granterInfo.granter && balance && (balance < 0.1))) {
                    data.fee.granter = granterInfo.granter;
                }

                if (data && data.fee && data.fee.granter && window.keplr) {
                    window.keplr.defaultOptions = {
                        sign: {
                            disableBalanceCheck: true,
                        },
                    };
                } else if (window.keplr) {
                    window.keplr.defaultOptions = {};
                }

                if (props.keys && props.keys.isNanoLedger) {
                    const Tx = {
                        msgs: [{
                            typeUrl: '/cosmos.bank.v1beta1.MsgSend',
                            value: {
                                fromAddress: props.address,
                                toAddress: toAddress,
                                amount: [{
                                    denom: denom,
                                    amount: String(price * (10 ** decimals)),
                                }],
                            },
                        }],
                        fee: {
                            amount: [{
                                amount: String(5000),
                                denom: config.COIN_MINIMAL_DENOM,
                            }],
                            gas: String(200000),
                        },
                        memo: mintResult && mintResult.memo,
                    };

                    props.aminoSignTx(Tx, props.address, (result) => {
                        if (result && result.transactionHash) {
                            if (result && result.code !== undefined && result.code !== 0) {
                                props.showMessage(result.raw_log || result.logs, 'error', result && result.transactionHash);
                                props.setTxHashInProgressFalse();

                                return;
                            }

                            props.fetchAllowances(props.address);
                            props.fetchBalance(props.address);
                            props.fetchProject(config.PROJECT_ID, (projectResult) => {
                                if (projectResult) {
                                    const data = projectResult && projectResult['pre_sale'] &&
                                    projectResult['pre_sale']['end_time'] &&
                                    moment().diff(projectResult['pre_sale']['end_time']) < 0
                                        ? projectResult['pre_sale'] : projectResult;
                                    const decimals = ibcToken && ibcToken.network && ibcToken.network.decimals;
                                    let value = data && data.price;
                                    value = value && value.amount && value.amount / (10 ** decimals);

                                    props.setPricePerNFT(value);
                                }
                            });
                            props.fetchMintLimit(config.PROJECT_ID);
                            props.setTxHashInProgressFalse();
                            props.successMintNFTDialog(result.transactionHash);
                            props.setSendTokensInProgress(false);
                            props.fetchMintQueue();
                            props.fetchTransactions(DEFAULT_SKIP, DEFAULT_LIMIT);
                            const queueTime = setInterval(() => {
                                props.fetchMintQueue((queueResult) => {
                                    if (props.mintQueue && props.mintQueue.length &&
                                        queueResult && queueResult.length &&
                                        props.mintQueue.length !== queueResult.length) {
                                        props.fetchTransactions(props.transactionsSkip, props.transactionsLimit);
                                        props.fetchTransfers(props.transfersSkip, props.transfersLimit);
                                        props.fetchProject(config.PROJECT_ID);
                                    }
                                    if (queueResult && !queueResult.length) {
                                        props.fetchTransactions(props.transactionsSkip, props.transactionsLimit);
                                        props.fetchTransfers(props.transfersSkip, props.transfersLimit);
                                        props.fetchProject(config.PROJECT_ID);
                                        clearInterval(queueTime);
                                    }
                                });
                            }, 5000);
                            props.setIntervalTime(queueTime);
                            props.fetchTransfers(DEFAULT_SKIP, DEFAULT_LIMIT);
                        } else {
                            props.setSendTokensInProgress(false);
                        }
                    });

                    return;
                }

                props.sign(data, props.address, (result, txBytes) => {
                    if (result) {
                        const txData = {
                            tx_bytes: txBytes,
                            mode: 'BROADCAST_MODE_SYNC',
                        };
                        props.txSignAndBroadCast(txData, (res1) => {
                            if (res1 && res1.txhash) {
                                let counter = 0;
                                const time = setInterval(() => {
                                    props.fetchTxHash(res1.txhash, (hashResult) => {
                                        if (hashResult) {
                                            if (hashResult && hashResult.code !== undefined && hashResult.code !== 0) {
                                                props.showMessage(hashResult.logs || hashResult.raw_log, 'error', hashResult && hashResult.hash);
                                                props.setTxHashInProgressFalse();
                                                props.setSendTokensInProgress(false);
                                                clearInterval(time);

                                                return;
                                            }

                                            props.fetchAllowances(props.address);
                                            props.fetchBalance(props.address);
                                            props.fetchProject(config.PROJECT_ID, (projectResult) => {
                                                if (projectResult) {
                                                    const data = projectResult && projectResult['pre_sale'] &&
                                                    projectResult['pre_sale']['end_time'] &&
                                                    moment().diff(projectResult['pre_sale']['end_time']) < 0
                                                        ? projectResult['pre_sale'] : projectResult;
                                                    const decimals = ibcToken && ibcToken.network && ibcToken.network.decimals;
                                                    let value = data && data.price;
                                                    value = value && value.amount && value.amount / (10 ** decimals);

                                                    props.setPricePerNFT(value);
                                                }
                                            });
                                            props.fetchMintLimit(config.PROJECT_ID);
                                            props.setTxHashInProgressFalse();
                                            props.successMintNFTDialog(res1 && res1.txhash);
                                            clearInterval(time);
                                            props.setSendTokensInProgress(false);
                                            props.fetchMintQueue();
                                            props.fetchTransactions(DEFAULT_SKIP, DEFAULT_LIMIT);
                                            const queueTime = setInterval(() => {
                                                props.fetchMintQueue((queueResult) => {
                                                    if (props.mintQueue && props.mintQueue.length &&
                                                        queueResult && queueResult.length &&
                                                        props.mintQueue.length !== queueResult.length) {
                                                        props.fetchTransactions(props.transactionsSkip, props.transactionsLimit);
                                                        props.fetchTransfers(props.transfersSkip, props.transfersLimit);
                                                        props.fetchProject(config.PROJECT_ID);
                                                    }
                                                    if (queueResult && !queueResult.length) {
                                                        props.fetchTransactions(props.transactionsSkip, props.transactionsLimit);
                                                        props.fetchTransfers(props.transfersSkip, props.transfersLimit);
                                                        props.fetchProject(config.PROJECT_ID);
                                                        clearInterval(queueTime);
                                                    }
                                                });
                                            }, 5000);
                                            props.setIntervalTime(queueTime);
                                            props.fetchTransfers(DEFAULT_SKIP, DEFAULT_LIMIT);
                                        }

                                        counter++;
                                        if (counter === 3) {
                                            if (hashResult && hashResult.code !== undefined && hashResult.code !== 0) {
                                                props.showMessage(hashResult.logs || hashResult.raw_log, 'error', hashResult && hashResult.hash);
                                                props.setTxHashInProgressFalse();
                                                clearInterval(time);

                                                return;
                                            }

                                            props.showMessage(variables[props.lang]['check_later']);
                                            props.setTxHashInProgressFalse();
                                            props.handleClose();
                                            clearInterval(time);
                                            props.setSendTokensInProgress(false);
                                        }
                                    });
                                }, 5000);
                            }
                        });
                    } else {
                        props.setSendTokensInProgress(false);
                    }
                });
            } else {
                props.setSendTokensInProgress(false);
            }
        });
    };

    const handleClose = () => {
        props.handleClose();
    };

    const inProgress = props.accountInProgress || props.inProgress || props.mintRequestInProgress ||
        props.signInProgress || props.inProgressTxHash;
    const toAddress = props.project && props.project['token_deposit_address'];
    const price = (props.pricePerNFTValue) * (props.value && props.value.count) && ((props.pricePerNFTValue) * (props.value && props.value.count)).toFixed(2);

    const denom = props.project && props.project.price && props.project.price.denom;
    const ibcToken = denom && props.ibcTokensList && props.ibcTokensList.length &&
        props.ibcTokensList.find((val) => val && val.ibc_denom_hash && (val.ibc_denom_hash === denom));

    return (
        <Dialog
            className="mint_dialog"
            open={props.open}
            onClose={handleClose}>
            {props.success
                ? <div className="mint_dialog_content mint_dialog_success">
                    <h2>{variables[props.lang]['mint_requested_successfully']}</h2>
                    <span>Transaction Hash</span>
                    <div className="hash">
                        <span>{props.hash}</span>
                        <CopyButton data={props.hash}/>
                    </div>
                    <div className="list_content">
                        <p className="denom_symbol">{props.project && props.project.denom && props.project.denom.symbol}</p>
                        <p className="denom_name">{props.project && props.project.denom && props.project.denom.name}</p>
                        <div>
                            <span>{variables[props.lang]['nft_s_minted']}</span>
                            <p className="nfts_count">{props.value && props.value.count}</p>
                        </div>
                        <div>
                            <span>{variables[props.lang]['tokens_transferred']}</span>
                            <div className="total_price">
                                <NetworkImages
                                    alt={ibcToken && ibcToken.network && ibcToken.network.display_denom}
                                    name={ibcToken && ibcToken.network && ibcToken.network.display_denom}/>
                                <span>{price}</span>
                                <p>{ibcToken && ibcToken.network && ibcToken.network.display_denom}</p>
                            </div>
                        </div>
                    </div>
                    <div className="mint_dialog_actions">
                        <Button
                            className="cancel_button"
                            onClick={handleClose}>
                            {variables[props.lang].close}
                        </Button>
                        <Button
                            className="confirm_button"
                            onClick={() => window.open(props.project && props.project.denom && props.project.denom.id
                                ? `${NUCLEUS_URL}/c/${props.project.denom.id}`
                                : `${NUCLEUS_URL}/account/${props.address}`)}>
                            <p>
                                {variables[props.lang]['go_to_market_place']}
                            </p>
                        </Button>
                    </div>
                    <div className="mint_dialog_actions">
                        <TwitterShareButton
                            className="confirm_button x"
                            title={variables[props.lang]['tweet_text'] + variables[props.lang]['tweet_text1'] + variables[props.lang]['tweet_text2']}
                            url="https://Tribbles.OmniFlix.Market">
                            <TwitterLogo/>
                            <p>
                                {variables[props.lang]['share_tweet']}
                            </p>
                        </TwitterShareButton>
                    </div>
                </div>
                : <div className="mint_dialog_content">
                    <h2>{variables[props.lang]['mint_nft_confirm_details']}</h2>
                    <div className="list_content">
                        <div>
                            <span>{variables[props.lang]['message_type']}</span>
                            <p>mintNFT</p>
                        </div>
                        <div>
                            <span>{variables[props.lang].to}</span>
                            <div className="hash_text to_address" title={toAddress}>
                                <p className="name">{toAddress}</p>
                                {toAddress && toAddress.slice(toAddress.length - 6, toAddress.length)}
                            </div>
                        </div>
                        <div>
                            <span>{variables[props.lang]['denom_symbol']}</span>
                            <p>{props.project && props.project.denom && props.project.denom.symbol}</p>
                        </div>
                        <div>
                            <span>{variables[props.lang]['denom_name']}</span>
                            <p>{props.project && props.project.denom && props.project.denom.name}</p>
                        </div>
                        <div>
                            <span>{variables[props.lang]['total_nfts']}</span>
                            <p className="nfts_count">{props.value && props.value.count}</p>
                        </div>
                        <div>
                            <span>{variables[props.lang]['total_price']}</span>
                            <div className="total_price">
                                <NetworkImages
                                    alt={ibcToken && ibcToken.network && ibcToken.network.display_denom}
                                    name={ibcToken && ibcToken.network && ibcToken.network.display_denom}/>
                                <span>{price}</span>
                                <p>{ibcToken && ibcToken.network && ibcToken.network.display_denom}</p>
                            </div>
                        </div>
                    </div>
                    {inProgress ? <CircularProgress className="full_screen"/> : null}
                    <div className="mint_dialog_actions">
                        <Button
                            className="cancel_button"
                            onClick={handleClose}>
                            {variables[props.lang].cancel}
                        </Button>
                        <Button
                            className="confirm_button"
                            disabled={inProgress}
                            onClick={handleConfirm}>
                            <p>
                                {inProgress
                                    ? variables[props.lang].processing + '...'
                                    : variables[props.lang].confirm}
                            </p>
                        </Button>
                    </div>
                </div>}
        </Dialog>
    );
};

MintNFTDialog.propTypes = {
    accountInProgress: PropTypes.bool.isRequired,
    addMintRequest: PropTypes.func.isRequired,
    address: PropTypes.string.isRequired,
    allowances: PropTypes.array.isRequired,
    aminoSignTx: PropTypes.func.isRequired,
    balance: PropTypes.array.isRequired,
    connectIBCAccount: PropTypes.func.isRequired,
    fetchAllowances: PropTypes.func.isRequired,
    fetchBalance: PropTypes.func.isRequired,
    fetchMintLimit: PropTypes.func.isRequired,
    fetchMintQueue: PropTypes.func.isRequired,
    fetchProject: PropTypes.func.isRequired,
    fetchTransactions: PropTypes.func.isRequired,
    fetchTransfers: PropTypes.func.isRequired,
    fetchTxHash: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    ibcTokensList: PropTypes.array.isRequired,
    ibcTokensListInProgress: PropTypes.bool.isRequired,
    inProgress: PropTypes.bool.isRequired,
    inProgressTxHash: PropTypes.bool.isRequired,
    keys: PropTypes.object.isRequired,
    lang: PropTypes.string.isRequired,
    mintQueue: PropTypes.array.isRequired,
    mintRequestInProgress: PropTypes.bool.isRequired,
    open: PropTypes.bool.isRequired,
    pricePerNFTValue: PropTypes.any.isRequired,
    project: PropTypes.object.isRequired,
    setIntervalTime: PropTypes.func.isRequired,
    setPricePerNFT: PropTypes.func.isRequired,
    setSendTokensInProgress: PropTypes.func.isRequired,
    setTxHashInProgressFalse: PropTypes.func.isRequired,
    showMessage: PropTypes.func.isRequired,
    sign: PropTypes.func.isRequired,
    signInProgress: PropTypes.bool.isRequired,
    success: PropTypes.bool.isRequired,
    successMintNFTDialog: PropTypes.func.isRequired,
    txSignAndBroadCast: PropTypes.func.isRequired,
    txSignAndBroadCastAminoSign: PropTypes.func.isRequired,
    value: PropTypes.object.isRequired,
    hash: PropTypes.any,
    intervalTime: PropTypes.number,
    transactionsLimit: PropTypes.number,
    transactionsSkip: PropTypes.number,
    transfersLimit: PropTypes.number,
    transfersSkip: PropTypes.number,
};

const stateToProps = (state) => {
    return {
        accountInProgress: state.account.wallet.connection.inProgress,
        address: state.account.wallet.connection.address,
        allowances: state.account.bc.allowances.value,
        balance: state.account.bc.balance.value,
        open: state.home.mintNFTDialog.open,
        value: state.home.mintNFTDialog.value,
        hash: state.home.mintNFTDialog.hash,
        ibcTokensList: state.account.ibc.ibcTokensList.value,
        ibcTokensListInProgress: state.account.ibc.ibcTokensList.inProgress,
        inProgressTxHash: state.account.bc.txHash.inProgress,
        inProgress: state.mint.sendTokens.inProgress,
        intervalTime: state.mint.intervalTime,
        keys: state.account.wallet.connection.keys,
        lang: state.language,
        mintQueue: state.mint.mintQueue.value,
        mintRequestInProgress: state.mint.mintRequest.inProgress,
        pricePerNFTValue: state.home.pricePerNFTValue,
        project: state.mint.project.value,
        signInProgress: state.account.bc.protoBufSign.inProgress,
        success: state.home.mintNFTDialog.success,
        transactionsLimit: state.home.transactions.limit,
        transactionsSkip: state.home.transactions.skip,
        transfersLimit: state.home.transfers.limit,
        transfersSkip: state.home.transfers.skip,
    };
};

const actionToProps = {
    aminoSignTx: aminoSignTxProtoTX,
    addMintRequest,
    connectIBCAccount,
    fetchAllowances,
    fetchBalance,
    fetchMintLimit,
    fetchMintQueue,
    fetchProject,
    fetchTransactions,
    fetchTransfers,
    fetchTxHash,
    handleClose: hideMintNFTDialog,
    setIntervalTime,
    setPricePerNFT,
    setSendTokensInProgress,
    setTxHashInProgressFalse,
    showMessage,
    sign: protoBufSigning,
    successMintNFTDialog,
    txSignAndBroadCast,
    txSignAndBroadCastAminoSign,
};

export default connect(stateToProps, actionToProps)(MintNFTDialog);
