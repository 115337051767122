/* eslint-disable */
import {configure, Reader, util, Writer} from "protobufjs/minimal";
import Long from "long";
import {Coin} from "../../cosmos/base/v1beta1/coin";
import {WeightedAddress} from "../../marketplace/v1beta1/listing";

export const protobufPackage = "OmniFlix.marketplace.v1beta1";

export interface MsgListNFT {
    id: string;
    nftId: string;
    denomId: string;
    price: Coin | undefined;
    owner: string;
    splitShares: WeightedAddress[];
}

export interface MsgListNFTResponse {
}

export interface MsgEditListing {
    id: string;
    price: Coin | undefined;
    owner: string;
}

export interface MsgEditListingResponse {
}

export interface MsgDeListNFT {
    id: string;
    owner: string;
}

export interface MsgDeListNFTResponse {
}

export interface MsgBuyNFT {
    id: string;
    price: Coin | undefined;
    buyer: string;
}

export interface MsgBuyNFTResponse {
}

function createBaseMsgListNFT(): MsgListNFT {
    return {
        id: "",
        nftId: "",
        denomId: "",
        price: undefined,
        owner: "",
        splitShares: [],
    };
}

export const MsgListNFT = {
    encode(message: MsgListNFT, writer: Writer = Writer.create()): Writer {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.nftId !== "") {
            writer.uint32(18).string(message.nftId);
        }
        if (message.denomId !== "") {
            writer.uint32(26).string(message.denomId);
        }
        if (message.price !== undefined) {
            Coin.encode(message.price, writer.uint32(34).fork()).ldelim();
        }
        if (message.owner !== "") {
            writer.uint32(42).string(message.owner);
        }
        for (const v of message.splitShares) {
            WeightedAddress.encode(v!, writer.uint32(50).fork()).ldelim();
        }
        return writer;
    },

    decode(input: Reader | Uint8Array, length?: number): MsgListNFT {
        const reader = input instanceof Reader ? input : new Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgListNFT();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.nftId = reader.string();
                    break;
                case 3:
                    message.denomId = reader.string();
                    break;
                case 4:
                    message.price = Coin.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.owner = reader.string();
                    break;
                case 6:
                    message.splitShares.push(
                        WeightedAddress.decode(reader, reader.uint32())
                    );
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromJSON(object: any): MsgListNFT {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            nftId: isSet(object.nftId) ? String(object.nftId) : "",
            denomId: isSet(object.denomId) ? String(object.denomId) : "",
            price: isSet(object.price) ? Coin.fromJSON(object.price) : undefined,
            owner: isSet(object.owner) ? String(object.owner) : "",
            splitShares: Array.isArray(object?.splitShares)
                ? object.splitShares.map((e: any) => WeightedAddress.fromJSON(e))
                : [],
        };
    },

    toJSON(message: MsgListNFT): unknown {
        const obj: any = {};
        message.id !== undefined && (obj.id = message.id);
        message.nftId !== undefined && (obj.nftId = message.nftId);
        message.denomId !== undefined && (obj.denomId = message.denomId);
        message.price !== undefined &&
        (obj.price = message.price ? Coin.toJSON(message.price) : undefined);
        message.owner !== undefined && (obj.owner = message.owner);
        if (message.splitShares) {
            obj.splitShares = message.splitShares.map((e) =>
                e ? WeightedAddress.toJSON(e) : undefined
            );
        } else {
            obj.splitShares = [];
        }
        return obj;
    },

    fromPartial<I extends Exact<DeepPartial<MsgListNFT>, I>>(
        object: I
    ): MsgListNFT {
        const message = createBaseMsgListNFT();
        message.id = object.id ?? "";
        message.nftId = object.nftId ?? "";
        message.denomId = object.denomId ?? "";
        message.price =
            object.price !== undefined && object.price !== null
                ? Coin.fromPartial(object.price)
                : undefined;
        message.owner = object.owner ?? "";
        message.splitShares =
            object.splitShares?.map((e) => WeightedAddress.fromPartial(e)) || [];
        return message;
    },
};

function createBaseMsgListNFTResponse(): MsgListNFTResponse {
    return {};
}

export const MsgListNFTResponse = {
    encode(_: MsgListNFTResponse, writer: Writer = Writer.create()): Writer {
        return writer;
    },

    decode(input: Reader | Uint8Array, length?: number): MsgListNFTResponse {
        const reader = input instanceof Reader ? input : new Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgListNFTResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromJSON(_: any): MsgListNFTResponse {
        return {};
    },

    toJSON(_: MsgListNFTResponse): unknown {
        const obj: any = {};
        return obj;
    },

    fromPartial<I extends Exact<DeepPartial<MsgListNFTResponse>, I>>(
        _: I
    ): MsgListNFTResponse {
        const message = createBaseMsgListNFTResponse();
        return message;
    },
};

function createBaseMsgEditListing(): MsgEditListing {
    return {id: "", price: undefined, owner: ""};
}

export const MsgEditListing = {
    encode(message: MsgEditListing, writer: Writer = Writer.create()): Writer {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.price !== undefined) {
            Coin.encode(message.price, writer.uint32(18).fork()).ldelim();
        }
        if (message.owner !== "") {
            writer.uint32(26).string(message.owner);
        }
        return writer;
    },

    decode(input: Reader | Uint8Array, length?: number): MsgEditListing {
        const reader = input instanceof Reader ? input : new Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgEditListing();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.price = Coin.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.owner = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromJSON(object: any): MsgEditListing {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            price: isSet(object.price) ? Coin.fromJSON(object.price) : undefined,
            owner: isSet(object.owner) ? String(object.owner) : "",
        };
    },

    toJSON(message: MsgEditListing): unknown {
        const obj: any = {};
        message.id !== undefined && (obj.id = message.id);
        message.price !== undefined &&
        (obj.price = message.price ? Coin.toJSON(message.price) : undefined);
        message.owner !== undefined && (obj.owner = message.owner);
        return obj;
    },

    fromPartial<I extends Exact<DeepPartial<MsgEditListing>, I>>(
        object: I
    ): MsgEditListing {
        const message = createBaseMsgEditListing();
        message.id = object.id ?? "";
        message.price =
            object.price !== undefined && object.price !== null
                ? Coin.fromPartial(object.price)
                : undefined;
        message.owner = object.owner ?? "";
        return message;
    },
};

function createBaseMsgEditListingResponse(): MsgEditListingResponse {
    return {};
}

export const MsgEditListingResponse = {
    encode(_: MsgEditListingResponse, writer: Writer = Writer.create()): Writer {
        return writer;
    },

    decode(input: Reader | Uint8Array, length?: number): MsgEditListingResponse {
        const reader = input instanceof Reader ? input : new Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgEditListingResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromJSON(_: any): MsgEditListingResponse {
        return {};
    },

    toJSON(_: MsgEditListingResponse): unknown {
        const obj: any = {};
        return obj;
    },

    fromPartial<I extends Exact<DeepPartial<MsgEditListingResponse>, I>>(
        _: I
    ): MsgEditListingResponse {
        const message = createBaseMsgEditListingResponse();
        return message;
    },
};

function createBaseMsgDeListNFT(): MsgDeListNFT {
    return {id: "", owner: ""};
}

export const MsgDeListNFT = {
    encode(message: MsgDeListNFT, writer: Writer = Writer.create()): Writer {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.owner !== "") {
            writer.uint32(18).string(message.owner);
        }
        return writer;
    },

    decode(input: Reader | Uint8Array, length?: number): MsgDeListNFT {
        const reader = input instanceof Reader ? input : new Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgDeListNFT();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.owner = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromJSON(object: any): MsgDeListNFT {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            owner: isSet(object.owner) ? String(object.owner) : "",
        };
    },

    toJSON(message: MsgDeListNFT): unknown {
        const obj: any = {};
        message.id !== undefined && (obj.id = message.id);
        message.owner !== undefined && (obj.owner = message.owner);
        return obj;
    },

    fromPartial<I extends Exact<DeepPartial<MsgDeListNFT>, I>>(
        object: I
    ): MsgDeListNFT {
        const message = createBaseMsgDeListNFT();
        message.id = object.id ?? "";
        message.owner = object.owner ?? "";
        return message;
    },
};

function createBaseMsgDeListNFTResponse(): MsgDeListNFTResponse {
    return {};
}

export const MsgDeListNFTResponse = {
    encode(_: MsgDeListNFTResponse, writer: Writer = Writer.create()): Writer {
        return writer;
    },

    decode(input: Reader | Uint8Array, length?: number): MsgDeListNFTResponse {
        const reader = input instanceof Reader ? input : new Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgDeListNFTResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromJSON(_: any): MsgDeListNFTResponse {
        return {};
    },

    toJSON(_: MsgDeListNFTResponse): unknown {
        const obj: any = {};
        return obj;
    },

    fromPartial<I extends Exact<DeepPartial<MsgDeListNFTResponse>, I>>(
        _: I
    ): MsgDeListNFTResponse {
        const message = createBaseMsgDeListNFTResponse();
        return message;
    },
};

function createBaseMsgBuyNFT(): MsgBuyNFT {
    return {id: "", price: undefined, buyer: ""};
}

export const MsgBuyNFT = {
    encode(message: MsgBuyNFT, writer: Writer = Writer.create()): Writer {
        if (message.id !== "") {
            writer.uint32(10).string(message.id);
        }
        if (message.price !== undefined) {
            Coin.encode(message.price, writer.uint32(18).fork()).ldelim();
        }
        if (message.buyer !== "") {
            writer.uint32(26).string(message.buyer);
        }
        return writer;
    },

    decode(input: Reader | Uint8Array, length?: number): MsgBuyNFT {
        const reader = input instanceof Reader ? input : new Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgBuyNFT();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.price = Coin.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.buyer = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromJSON(object: any): MsgBuyNFT {
        return {
            id: isSet(object.id) ? String(object.id) : "",
            price: isSet(object.price) ? Coin.fromJSON(object.price) : undefined,
            buyer: isSet(object.buyer) ? String(object.buyer) : "",
        };
    },

    toJSON(message: MsgBuyNFT): unknown {
        const obj: any = {};
        message.id !== undefined && (obj.id = message.id);
        message.price !== undefined &&
        (obj.price = message.price ? Coin.toJSON(message.price) : undefined);
        message.buyer !== undefined && (obj.buyer = message.buyer);
        return obj;
    },

    fromPartial<I extends Exact<DeepPartial<MsgBuyNFT>, I>>(
        object: I
    ): MsgBuyNFT {
        const message = createBaseMsgBuyNFT();
        message.id = object.id ?? "";
        message.price =
            object.price !== undefined && object.price !== null
                ? Coin.fromPartial(object.price)
                : undefined;
        message.buyer = object.buyer ?? "";
        return message;
    },
};

function createBaseMsgBuyNFTResponse(): MsgBuyNFTResponse {
    return {};
}

export const MsgBuyNFTResponse = {
    encode(_: MsgBuyNFTResponse, writer: Writer = Writer.create()): Writer {
        return writer;
    },

    decode(input: Reader | Uint8Array, length?: number): MsgBuyNFTResponse {
        const reader = input instanceof Reader ? input : new Reader(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgBuyNFTResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
            }
        }
        return message;
    },

    fromJSON(_: any): MsgBuyNFTResponse {
        return {};
    },

    toJSON(_: MsgBuyNFTResponse): unknown {
        const obj: any = {};
        return obj;
    },

    fromPartial<I extends Exact<DeepPartial<MsgBuyNFTResponse>, I>>(
        _: I
    ): MsgBuyNFTResponse {
        const message = createBaseMsgBuyNFTResponse();
        return message;
    },
};

export interface Msg {
    ListNFT(request: MsgListNFT): Promise<MsgListNFTResponse>;

    EditListing(request: MsgEditListing): Promise<MsgEditListingResponse>;

    DeListNFT(request: MsgDeListNFT): Promise<MsgDeListNFTResponse>;

    BuyNFT(request: MsgBuyNFT): Promise<MsgBuyNFTResponse>;
}

export class MsgClientImpl implements Msg {
    private readonly rpc: Rpc;

    constructor(rpc: Rpc) {
        this.rpc = rpc;
        this.ListNFT = this.ListNFT.bind(this);
        this.EditListing = this.EditListing.bind(this);
        this.DeListNFT = this.DeListNFT.bind(this);
        this.BuyNFT = this.BuyNFT.bind(this);
    }

    ListNFT(request: MsgListNFT): Promise<MsgListNFTResponse> {
        const data = MsgListNFT.encode(request).finish();
        const promise = this.rpc.request(
            "OmniFlix.marketplace.v1beta1.Msg",
            "ListNFT",
            data
        );
        return promise.then((data) => MsgListNFTResponse.decode(new Reader(data)));
    }

    EditListing(request: MsgEditListing): Promise<MsgEditListingResponse> {
        const data = MsgEditListing.encode(request).finish();
        const promise = this.rpc.request(
            "OmniFlix.marketplace.v1beta1.Msg",
            "EditListing",
            data
        );
        return promise.then((data) =>
            MsgEditListingResponse.decode(new Reader(data))
        );
    }

    DeListNFT(request: MsgDeListNFT): Promise<MsgDeListNFTResponse> {
        const data = MsgDeListNFT.encode(request).finish();
        const promise = this.rpc.request(
            "OmniFlix.marketplace.v1beta1.Msg",
            "DeListNFT",
            data
        );
        return promise.then((data) =>
            MsgDeListNFTResponse.decode(new Reader(data))
        );
    }

    BuyNFT(request: MsgBuyNFT): Promise<MsgBuyNFTResponse> {
        const data = MsgBuyNFT.encode(request).finish();
        const promise = this.rpc.request(
            "OmniFlix.marketplace.v1beta1.Msg",
            "BuyNFT",
            data
        );
        return promise.then((data) => MsgBuyNFTResponse.decode(new Reader(data)));
    }
}

interface Rpc {
    request(
        service: string,
        method: string,
        data: Uint8Array
    ): Promise<Uint8Array>;
}

type Builtin =
    | Date
    | Function
    | Uint8Array
    | string
    | number
    | boolean
    | undefined;

export type DeepPartial<T> = T extends Builtin
    ? T
    : T extends Array<infer U>
        ? Array<DeepPartial<U>>
        : T extends ReadonlyArray<infer U>
            ? ReadonlyArray<DeepPartial<U>>
            : T extends {}
                ? { [K in keyof T]?: DeepPartial<T[K]> }
                : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
    ? P
    : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<Exclude<keyof I, KeysOfUnion<P>>,
    never>;

// If you get a compile-error about 'Constructor<Long> and ... have no overlap',
// add '--ts_proto_opt=esModuleInterop=true' as a flag when calling 'protoc'.
if (util.Long !== Long) {
    util.Long = Long as any;
    configure();
}

function isSet(value: any): boolean {
    return value !== null && value !== undefined;
}
