import React from 'react';
import { ReactComponent as PoweredByIcon } from '../../assets/powered_by.svg';
import { socialLinks } from '../../config';

export const Footer = () => {
    return (
        <div className="footer">
            <a href={socialLinks.POWERED_BY} rel="noopener noreferrer" target="_blank">
                <PoweredByIcon/>
            </a>
        </div>
    );
};

export default Footer;
