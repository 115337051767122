import React from 'react';
import serversIcon from '../../assets/servers.png';
import './index.css';

const ServerDown = () => {
    return (
        <div className="server_down">
            <img alt="server down" src={serversIcon}/>
            Server Down
        </div>
    );
};

export default ServerDown;
